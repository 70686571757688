// @ts-check

import moment from './moment-timezone.service';

// exclude only active meds from the list of medications available to add
export function getUnusedMedicationsList(user, group) {
  const activeMedicationIds = user?.plan?.medications.filter((m) => m.status === 'active').map((m) => m.medication.id);
  return group.medications.filter(
    (m) => activeMedicationIds?.indexOf(m.id) === -1
  );
}

// imported directly from previous implementation
// patient-portal/client/app/views/edit-med/edit-med.controller.js:69
export function getTimeOptions() {
  const timeOptions = [];

  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < 48; j++) {
    const minutes = (j % 2) * 30;
    const hours = j % 2 ? (j - 1) / 2 : j / 2;
    timeOptions.push({
      value: j.toString(),
      name: moment(`${hours}:${minutes}`, 'HH:mm').format('LT'),
      minute: minutes,
      hour: hours
    });
  }

  return timeOptions;
}

export function isStrictDoseSchedule(medId, groupConfig) {
  const { strictDoseMedicationList } = groupConfig;

  return (
    strictDoseMedicationList
    && medId
    && strictDoseMedicationList.indexOf(medId) !== -1
  );
}


// TODO: revisit logic as to determine which dose range to use.
export function getStrictMedicationDoseSchedule(medId, groupConfig) {
  const medicationConfig = groupConfig.medications.find((m) => m.id === medId);
  const doseRange = medicationConfig.doseRanges[0];

  return {
    dosesPerDay: doseRange.administrationsPerDay.min,
    puffsPerDose: doseRange.unitDosesPerAdministration.min
  };
}

export function formatMedication(medication, groupConfig) {
  if (!medication) return null;

  const medId = medication.id;

  const isStrict = groupConfig
    ? isStrictDoseSchedule(medId, groupConfig)
    : false;

  // default to 1s
  const doseSchedule = isStrict
    ? getStrictMedicationDoseSchedule(medId, groupConfig)
    : {
      dosesPerDay: 1,
      puffsPerDose: 1
    };

  return {
    ...medication,
    isStrictDoseSchedule: isStrict,
    doseSchedule
  };
}

export function isRestricted(medication, groupConfig) {
  if (medication && groupConfig.strictDoseMedicationList) {
    const { id } = medication;
    const { strictDoseMedicationList } = groupConfig;
    return strictDoseMedicationList.includes(id);
  }
  return false;
}

export function hasSensors(med) {
  return med && med.sensors && med.sensors.length > 0;
}

export function searchPlanForSensor(medications, mac) {
  if (!medications) return null;
  return medications.find(
    (med) => med.sensors
      && med.sensors.length
      && med.sensors.find((s) => s.mac.slice(9) === mac)
  );
}

const isMedType = (type) => (med) => med && med.type === type;
export const isController = isMedType('controller');
export const isRescue = isMedType('rescue');

export const defaultTimesForDoses = (doses) => {
  switch (doses) {
    case 1:
      // 9am
      return [{
        hour: 9,
        minute: 0,
      }];

    case 2:
      // 8am, 8pm
      return [{
        hour: 8,
        minute: 0,
      }, {
        hour: 20,
        minute: 0,
      }];

    case 3:
      // 8am, 2pm, 8pm
      return [{
        hour: 8,
        minute: 0,
      }, {
        hour: 14,
        minute: 0,
      }, {
        hour: 20,
        minute: 0,
      }];

    case 4:
      // 8am, 12pm, 4pm, 8pm
      return [{
        hour: 8,
        minute: 0,
      }, {
        hour: 12,
        minute: 0,
      }, {
        hour: 16,
        minute: 0,
      }, {
        hour: 20,
        minute: 0,
      }];

    case 0:
    default:
      return [];
  }
};
