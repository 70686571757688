import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HeaderControls from './HeaderControls';
import { TZ } from '../../../services/moment.service';


const TimelineHeading = ({
  title,
  userId,
  isLoading,
  canModify,
  addEvent,
  t
}) => (
  <div className="clearfix">
    <Row>
      <Col sm={12} md={4}>
        <h1 id="timeline-title" className="page-title page-title--indent">
          {title}
        </h1>
        <h4 id="timeline-timezone">({TZ})</h4>
      </Col>
      <Col sm={12} md={8} id="timeline-sensor-sync-container">
        {!isLoading && (
          <HeaderControls
            userId={userId}
            canModify={canModify}
            addEvent={addEvent}
            t={t}
          />
        )}
      </Col>
    </Row>
  </div>
);

TimelineHeading.propTypes = {
  addEvent: PropTypes.func.isRequired,
  canModify: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default TimelineHeading;
