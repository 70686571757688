import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import withSession from '../hoc/withSession';
import { userIsPhysician } from '../services/profile.service';
import { getGroupConfig } from 'resources/group/group.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ControlPatient from 'pages/ControlPatient/ControlPatient';

const mapStateToProps = (state) => {
  const { currentGroup } = state.rGroup;
  return {
    currentGroup,
  };
};

const actionCreators = {
  getGroupConfig
};

const AuthenticatedRoute = ({
  currentGroup,
  groupId,
  component: Component,
  session, // from withSession
  layout,
  ...rest
}) => {

  useEffect(() => {
    getGroupConfig(groupId);
  });
  return <Route
    {...rest}
    render={props => {

      if (!currentGroup?.patientPortalEnabled) {
        return (
          <ControlPatient />
        )
      }

      else if (session?.id) {
        return userIsPhysician(session) && props.location.pathname !== '/physician' ? (
          <Redirect
            to={{
              pathname: '/physician',
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        );
      }

      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      );
    }}
  />
};

export default compose(
  connect(mapStateToProps, actionCreators),
  withSession
)(AuthenticatedRoute);
