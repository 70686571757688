import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { info as notifyInfo } from 'react-notification-system-redux';

import { getUserById } from 'resources/user/user.actions';
import { getEventList as getEvents } from 'resources/event/event.actions';
import { getGroupConfig } from 'resources/group/group.actions';
import { doesCurrentUserHaveModifyAccess } from 'services/auth.service';

import Timeline from './Timeline';


const mapStateToProps = (state) => {
  const { me, currentUser } = state.rUser;
  const { events, isLoading, showNextPageButton, nextPage } = state.rEvent;
  const { currentGroup } = state.rGroup;

  return {
    me,
    currentUser,
    currentGroup,
    events,
    isLoading,
    showNextPageButton,
    nextPage,
    isInitialLoad: isLoading && events.length < 1,
    doesCurrentUserHaveModifyAccess: doesCurrentUserHaveModifyAccess(me, currentUser)
  };
};

const actionCreators = {
  getUserById,
  getEvents,
  getGroupConfig,
  notifyInfo
};

export default compose(
  withTranslation(['translations', 'timeline']),
  connect(
    mapStateToProps,
    actionCreators
  )
)(Timeline);
