import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  formatDate,
  getTimeValues,
  getAccurateDateTimeValue,
} from 'services/moment.service';
import { isSensorEvent } from 'services/util.service';

import MedicationOptions from 'pages/Medications/components/MedicationOptions';

import TriggerOptions from 'components/TriggerOptions';
import SymptomOptions from 'components/SymptomOptions';
import EventAddress from 'components/EventAddress';
import PuffSelection from 'components/PuffSelection';
import DatePickerField from 'components/DatePickerField';


class EventForm extends Component {
  constructor(props) {
    super(props);

    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleEventTimeChange = this.handleEventTimeChange.bind(this);
    this.handleEventDateChange = this.handleEventDateChange.bind(this);
  }

  getEventDateTimeValues() {
    const { dateTime } = this.props;
    const eventDate = formatDate(dateTime);
    const eventTimeValues = getTimeValues(dateTime);

    return {
      date: eventDate,
      time: eventTimeValues,
    };
  }

  handleEventTimeChange(value) {
    if (!value) {
      return;
    }

    const { date } = this.getEventDateTimeValues();
    this.handleDateTimeChange(date, value);
  }

  handleEventDateChange(date) {
    const { time } = this.getEventDateTimeValues();

    this.handleDateTimeChange(date, time);
  }

  handleDateTimeChange(date, timeValues) {
    const dateValue = getAccurateDateTimeValue(date, timeValues).format();
    const { setDate } = this.props;

    setDate(dateValue);
  }

  render() {
    const {
      event,
      medication,
      dateError,
      setPuffs,
      setPreemptive,
      onSubmit,
      close,
      updateList,
      deleteEvent,
      type,
      t
    } = this.props;
    const controllerEvent = type === 'controller';
    const rescueEvent = type === 'rescue';
    const colProps = controllerEvent
      ? { md: 6 }
      : { md: 4 };
    const { date, time } = this.getEventDateTimeValues();
    const isEventFromSensor = isSensorEvent(event);
    const dateTimeInputInfo = isEventFromSensor ? t('UNABLE_TO_EDIT_SENSORED_EVENT') : null;

    return (
      <form onSubmit={onSubmit}>
        <Modal.Header>
          <h2 className="modal-title">{t(`${type.toUpperCase()}_EVENT`)}</h2>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={12} {...colProps}>
                {event.medicationId && (
                  <>
                    <MedicationOptions
                      value={event.medicationId}
                      options={[medication]}
                      disabled
                      t={t}
                    />
                    {rescueEvent && (
                      <div>
                        <EventAddress address={event.address} />
                      </div>
                    )}
                    <PuffSelection
                      puffs={event.unitDoses}
                      onChange={setPuffs}
                      disabled={isEventFromSensor}
                      t={t}
                    />
                  </>
                )}

                <DatePickerField
                  label={t('DATE')}
                  placeholder={t('DATE')}
                  error={dateError}
                  info={dateTimeInputInfo}
                  value={date}
                  disabled={isEventFromSensor}
                  onDateChange={this.handleEventDateChange}
                />
                <TimePicker
                  disabled={isEventFromSensor}
                  label={t('TIME')}
                  value={time}
                  onChange={this.handleEventTimeChange}
                  value={time}
                  info={dateTimeInputInfo}
                  error={dateError}
                  ariaLabel={
                    isEventFromSensor
                      ? 'USAGE_RECORDED_FOR_TIME'
                      : 'USAGE_RECORDED_FOR_TIME_EDITABLE'
                  }
                />

                {rescueEvent && (
                  <div
                    role="group"
                    aria-label={t('PREEMPTIVE')}
                    aria-describedby="preemptive-group-description"
                  >
                    <input
                      id="preemptive"
                      type="checkbox"
                      aria-label={t('PREEMPTIVE')}
                      onChange={setPreemptive}
                      checked={event.preemptive}
                    />
                    <label htmlFor="preemptive" id="preemptive-group-title">
                      {t('PREEMPTIVE')}
                    </label>
                    <p
                      className="form-group-message info"
                      id="preemptive-group-description"
                    >
                      {t('PREEMPTIVE_DESCRIPTION')}
                    </p>
                  </div>
                )}
              </Col>
              {!controllerEvent && (
                <>
                  <Col
                    sm={12}
                    md={4}
                    role="group"
                    aria-labelledby="triggers-group-label"
                  >
                    <h3
                      id="triggers-group-label"
                      className="trigger-symptom-section-title"
                    >
                      {t('TRIGGERS')}
                    </h3>
                    <div className="trigger-list-overflow-container">
                      <TriggerOptions
                        items={event.triggers}
                        update={updateList('triggers')}
                      />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    role="group"
                    aria-labelledby="symptoms-group-label"
                  >
                    <h3
                      className="trigger-symptom-section-title"
                      id="symptoms-group-label"
                    >
                      {t('SYMPTOMS')}
                    </h3>
                    <SymptomOptions
                      items={event.symptoms}
                      update={updateList('symptoms')}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col sm={4} md={6} className="text-left">
                {event && event.id && (
                  <Button variant="danger" onClick={deleteEvent}>
                    {t('DELETE')}
                  </Button>
                )}
              </Col>
              <Col sm={8} md={6} className="text-right">
                <Button onClick={close} variant="outline-secondary">
                  {t('CLOSE')}
                </Button>
                &nbsp;&nbsp;
                <Button variant="success" type="submit">
                  {t('SAVE')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </form>
    );
  }
}

EventForm.propTypes = {
  event: PropTypes.object.isRequired,
  medication: PropTypes.object,
  setPuffs: PropTypes.func.isRequired,
  setPreemptive: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  dateTime: PropTypes.string.isRequired,
  dateError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
};

EventForm.defaultProps = {
  dateError: null,
  disabled: false,
  medication: null,
};

export default EventForm;
