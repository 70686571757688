import React from 'react';
import PropTypes from 'prop-types';
import ItemList from './ItemList';


const TriggerList = ({ event, t }) => (
  <ItemList title="TRIGGERS" prefix="TRIGGER" items={event.triggers} t={t} />
);

TriggerList.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default TriggerList;
