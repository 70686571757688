import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


const PhCheckbox = ({ id, labelText, checked, onChange }) => (
  <div className="ph-checkbox" key={id}>
    <input
      id={id}
      type="checkbox"
      aria-label={labelText}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id}>
      <div className="cursor-pointer">{labelText}</div>
    </label>
  </div>
);

PhCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PhCheckbox;
