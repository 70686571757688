import {
  GROUP_GET_GROUP_REQUEST,
  GROUP_GET_GROUP_SUCCESS,
  GROUP_GET_GROUP_FAILURE
} from './group.types';
import { fetchGroup } from './group.controller';
import { makeActionCreator } from '../../lib/reduxUtils';


const getGroup = makeActionCreator(GROUP_GET_GROUP_REQUEST);
const setGroup = makeActionCreator(GROUP_GET_GROUP_SUCCESS, 'payload');
const groupError = makeActionCreator(GROUP_GET_GROUP_FAILURE, 'error');

export function getGroupConfig(groupId) {
  return (dispatch) => {
    dispatch(getGroup());
    fetchGroup(groupId)
      .then((response) => dispatch(setGroup(response)))
      .catch((error) => dispatch(groupError(error)));
  };
}
