export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    let action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export const pagingTokenFrom = (queryParams) =>
  queryParams && queryParams.pagingToken && queryParams.pagingToken.length > 0
    ? queryParams.pagingToken
    : null;
