import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { logout } from 'services/auth.service';
import { getSupportUrl } from 'services/config.service';
import { patientType } from 'resources/user/user.types';

import './small-nav.scss';


const SmallNav = ({
  currentUser,
  getUserById,
  getUserProfile,
  me,
  t
}) => {
  function selectUser(userId) {
    if (currentUser.id !== userId) {
      if (userId === me.id) {
        getUserProfile();
      } else {
        getUserById(userId);
      }
    }
  }

  function goToLink(shortPath) {
    return `/${currentUser.id}/${shortPath}`;
  }

  return (
    <div className="small-nav">
      <Navbar collapseOnSelect expand="lg">
        <NavbarBrand />
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav>
            {currentUser.plan ? (
              <>
                <Nav.Link
                  as={Link}
                  href={goToLink('medications')}
                  to={goToLink('medications')}
                >
                  {t('medications:TREATMENTS_TITLE')}
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  href={goToLink('timeline')}
                  to={goToLink('timeline')}
                >
                  {t('TIMELINE_TITLE')}
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  href={goToLink('trends')}
                  to={goToLink('trends')}
                >
                  {t('TRENDS_TITLE')}
                </Nav.Link>
              </>
            ) : null}
            <Nav.Link
              as={Link}
              href={goToLink('profile')}
              to={goToLink('profile')}
            >
              {t('PROFILE_TITLE')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              href={goToLink('notifications')}
              to={goToLink('notifications')}
            >
              {t('NOTIFICATIONS_TITLE')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              href={goToLink('sharing')}
              to={goToLink('sharing')}
            >
              {t('SHARING_TITLE')}
            </Nav.Link>

            {me.following ? (
              <>
                <Nav.Link
                  onClick={() => selectUser(me.id)}
                  className={classNames(
                    'header-navigation-select-user-option',
                    { 'is-active': me.id === currentUser.id }
                  )}
                >
                  <span className="profile-name">
                    {`${me.givenName} ${me.familyName}`} ({t('HEADER_YOU')})
                  </span>
                </Nav.Link>
                {me.following.map((following) => (
                  <Nav.Link
                    key={`${following.id}`}
                    onClick={() => selectUser(following.id)}
                    className={classNames(
                      'header-navigation-select-user-option',
                      { 'is-active': following.id === currentUser.id }
                    )}
                  >
                    {following.givenName} {following.familyName}
                  </Nav.Link>
                ))}
              </>
            ) : null}
            <NavItem>
              <hr />
            </NavItem>
            <Nav.Link
              href={getSupportUrl(currentUser.language.toLowerCase())}
              target="_blank"
            >
              ?
            </Nav.Link>
            <Button
              variant="link"
              onClick={() => logout()}
              style={{ textAlign: 'left', paddingLeft: 0 }}
            >
              {t('HEADER_SIGN_OUT')}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

SmallNav.propTypes = {
  currentUser: patientType.isRequired,
  getUserById: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  me: patientType.isRequired,
  t: PropTypes.func.isRequired,
};

export default SmallNav;
