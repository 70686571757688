import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Caret from 'components/Icons/Caret';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import OptionsList from 'components/PhSelect/OptionsList';
import PhInputLabelText from 'components/PhInput/PhInputLabelText/PhInputLabelText';
import PhInputHint from 'components/PhInput/PhInputHint/PhInputHint';
import './PhSelect.scss';

export default function PhSelect({
  className,
  errorMessage = 'ERROR_MESSAGE',
  hasError,
  hint,
  isDisabled = false,
  label,
  name,
  onChange,
  options,
  placeholder = '',
  value
}) {
  const [selectedOption, setSelectedOption] = useState(value || undefined);
  const [show, setShow] = useState(false);

  const nodeRef = useRef();

  function handleOutsideClick(e) {
    if ((e?.target && !nodeRef) || !nodeRef.current?.contains(e.target)) {
      setShow(false);
      return;
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  });

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  function selectOption(value) {
    setSelectedOption(value);
    setShow(false);
    if (onChange) {
      onChange(value);
    }
  }

  function toggleOptions() {
    setShow(!show);
  }

  function getOptionObject(value) {
    if (value) {
      const obj = options.filter((opt) => opt.value === value)[0];
      return obj;
    }
    return '';
  }

  const cssClass = className ? className : '';
  const optionObj = selectedOption ? getOptionObject(selectedOption) : null;

  return (
    <div
      className={`ph-input ph-select ${cssClass} ${hasError ? 'error' : ''} 
         `}
      ref={nodeRef}
    >
      <label htmlFor={name} id={`select-${name}`}>
        <PhInputLabelText>{label}</PhInputLabelText>
        {hint ? <PhInputHint>{hint}</PhInputHint> : null}
        <button
          aria-disabled={isDisabled}
          aria-expanded={show ? true : false}
          aria-haspopup="listbox"
          aria-labelledby={`select-${name}`}
          className={`listbox-toggle ph-input--field`}
          onClick={() => (!isDisabled ? toggleOptions() : null)}
          type="button"
        >
          <div className="selected-value">
            <div className="select-label">
              {optionObj ? (
                <span>
                  {optionObj.image ? (
                    <img src={optionObj.image} alt={(optionObj.alt = '')} />
                  ) : null}{' '}
                  <div>{optionObj.name}</div>
                </span>
              ) : (
                <div>{placeholder}</div>
              )}
            </div>
            <Caret alt="" />
          </div>
        </button>
        {show ? (
          <OptionsList
            label={label}
            name={name}
            options={options}
            selectOption={selectOption}
            selectedOption={selectedOption}
            toggleOptions={toggleOptions}
          />
        ) : null}
        {hasError ? (
          <ErrorMessage iconSize="16" message={errorMessage} />
        ) : null}
      </label>
    </div>
  );
}

PhSelect.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  hint: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
