import React from 'react';

const FooterLink = ({ url, text, t, id }) => (
  <p className="footer-link-container">
    <a
      id={id}
      href={url}
      target="_blank"
      className="footer-link"
      rel="noopener noreferrer"
    >
      {t(text)}
    </a>
  </p>
);

export default FooterLink;
