import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import moment from 'services/moment-timezone.service';
import { getSortedEventsByDate } from 'services/timeline.service';
import { localTime } from 'services/moment.service';


class SelectEventModal extends Component {
  constructor(props) {
    super(props);
    this.editUsage = this.editUsage.bind(this);
  }

  editUsage(selectedUsage) {
    const { events, onSelect } = this.props;
    const selectedEvent = getSortedEventsByDate(
      events.filter((e) => e.id === selectedUsage.id)
    );

    if (
      selectedEvent
      && selectedEvent.length > 0
      && selectedEvent[0].events
      && selectedEvent[0].events.length > 0
    ) {
      onSelect(selectedEvent[0].events[0]);
    }
  }

  render() {
    const { show, onHide, selectedEvent, t } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <h2 className="modal-title">
            {`
              ${moment(selectedEvent.date).format('ll')} -
              ${selectedEvent.medication.name}
            `}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h3 className="no-margin-bottom">{t('SELECT_DOSE')}</h3>
              {selectedEvent.usageList.map((usage, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Row className="timeline-date-row" key={index}>
                  <Col xs={6} className="text-left">
                    <span className="timeline-usage">
                      {`${localTime(usage.date, 'LT')} | ${usage.puffs} ${t(
                        'PUFFS'
                      )}`}
                    </span>
                  </Col>
                  <Col xs={6} className="text-right">
                    <Button variant="outline-secondary" onClick={() => this.editUsage(usage)}>
                      {`${t('EDIT_DOSE')} ${index + 1}`}
                    </Button>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col xs={6} xsOffset={6} className="text-right">
            <Button onClick={onHide}>{t('CLOSE')}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}

SelectEventModal.propTypes = {
  t: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

SelectEventModal.defaultProps = {};

export default SelectEventModal;
