import React from 'react';
import PropTypes from 'prop-types';
import FlexRow from 'components/FlexRow';
import Button from 'react-bootstrap/Button';
import { login, logout } from 'services/auth.service';
import { getDefaultPage, getSupportUrl } from 'services/config.service';
import SmallNav from './SmallNav/SmallNav';
import BigNav from './BigNav/BigNav';
import { patientType } from '../../resources/user/user.types';
import './header.scss';
import { Link, Redirect } from 'react-router-dom';
import { currentGroup } from 'lib/testData';
import { getGroupConfig } from 'resources/group/group.actions';


class Header extends React.Component {


  isEnglish() {
    const { i18n } = this.props;
    return i18n.language === 'en' || i18n.language.split('-')[0] === 'en';
  }



  render() {
    const {
      currentUser,
      getUserById,
      getUserProfile,
      i18n,
      isUserLoggedIn,
      me,
      t,
      userIsPhysician
    } = this.props;

    return (
      <FlexRow container="header" className="header">
        <FlexRow className="row-max-width">
          <Link to={{ pathname: `/` }}>
            <div className="header__logo-container header__logo-link">
              {i18n.language === 'ja-JP' ? (
                <div
                  className="header__logo header__logo-jp"
                  role="img"
                  aria-label="Propeller logo"
                />
              ) : (
                <div
                  className="header__logo"
                  role="img"
                  aria-label="Propeller logo"
                />
              )}
            </div>
          </Link>
          {!isUserLoggedIn ? (
            <div className="sub-nav">
              {this.isEnglish() ? (
                <Button
                  variant="outline-dark"
                  href="https://www.propellerhealth.com/how-it-works/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('LEARN_MORE')}
                </Button>
              ) : null}
              &nbsp;&nbsp;
              <Button onClick={() => login()}>{t('LOGIN')}</Button>
            </div>
          ) : null}

          {userIsPhysician ? <Redirect to="/physician" /> : null}

          {isUserLoggedIn && !userIsPhysician ? (
            <>
              <BigNav
                currentUser={currentUser}
                getDefaultPage={getDefaultPage}
                getSupportUrl={getSupportUrl}
                logout={logout}
                getUserById={getUserById}
                getUserProfile={getUserProfile}
                me={me}
                t={t}
              />
              <SmallNav
                currentUser={currentUser}
                getDefaultPage={getDefaultPage}
                getSupportUrl={getSupportUrl}
                logout={logout}
                getUserById={getUserById}
                getUserProfile={getUserProfile}
                me={me}
                t={t}
              />
            </>
          ) : null}
        </FlexRow>
      </FlexRow>
    );
  }
}

Header.propTypes = {
  currentUser: patientType,
  getUserById: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  me: patientType,
  t: PropTypes.func.isRequired,
  userIsPhysician: PropTypes.bool
};

Header.defaultProps = {
  currentUser: null,
  me: null,
  userIsPhysician: false,
};

export default Header;
