import React from 'react';
import { useTranslation } from 'react-i18next';
import loadingSpinner from 'assets/images/loading-spinner.svg';

const LoadingSpinner = () => {
  const { t } = useTranslation('translations');
  return (
    <img
      className="loading-spinner"
      src={loadingSpinner}
      alt={t('LOADING')}
      width="250"
      height="250"
    />
  );
};

export default LoadingSpinner;
