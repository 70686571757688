import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


const CloseButton = ({ closeToast, t }) => (
  <button
    type="button"
    className="toast__close-btn"
    aria-label={t('CLOSE')}
    onClick={closeToast}
  >
    ✖
  </button>
);

CloseButton.propTypes = {
  t: PropTypes.func.isRequired,
  closeToast: PropTypes.func,
};

CloseButton.defaultProps = {
  closeToast: () => {},
};

export default CloseButton;
