import moment from 'services/moment-timezone.service';

// TODO: tests
export const diffInDays = (date1, date2) => {
  const a = moment(date1);
  const b = moment(date2);
  return a.diff(b, 'days');
};

export const TZ = moment.tz.guess();

export function localTime(aDate, aFormat) {
  return moment.tz(aDate, TZ).format(aFormat);
}

export const addDays = (date, days) => moment(date).add(days, 'days');

export const getShortDate = (date) => moment(date).format('L').replace(new RegExp(`[^.]?${moment(date).format('YYYY')}.?`), '');

export const COMMON_DATE_FORMAT = 'YYYY-MM-DD';

export const getLocaleWeekdaysShort = (date) => moment.weekdaysShort(moment(date));
export const getFirstDayOfWeek = () => moment.localeData().firstDayOfWeek();

export const getLocaleDateFormat = (format = 'LT') => moment.localeData().longDateFormat(format);

export const getLocalTimeFormat = () => getLocaleDateFormat('LT');

export const shouldUseAmPmFormat = (format = getLocalTimeFormat()) => (/a/i).test(format);

export const getMomentDate = (date) => {
  if (!date) {
    return null;
  }

  return date instanceof moment ? date : moment(date);
};

export const formatDate = (date, format = COMMON_DATE_FORMAT) => {
  if (!date) {
    return null;
  }

  const momentDate = getMomentDate(date);

  return momentDate.format(format);
};

export const getPastDay = (number, startDate = new Date()) => moment(startDate).subtract(number, 'days');

export const getTimeValues = (date) => {
  if (!date) {
    return null;
  }

  const momentDate = getMomentDate(date);
  const hour = momentDate.hours();
  const minute = momentDate.minutes();

  return {
    hour,
    minute,
  };
};

export const getAccurateDateTimeValue = (date, timeValues) => {
  const { hour, minute } = timeValues;

  return moment(date).hour(hour).minute(minute);
};

export default moment;
