import React from 'react';
import PropTypes from 'prop-types';

import { symptomEvent } from 'services/events.service';
import momentTimezone from 'services/moment-timezone.service';
import './usageTimes.scss';


function puffsDisplay(usage, t) {
  // this is the wrong way to handle numbers
  const puffText = usage.puffs === 1 ? t('PUFF') : t('PUFFS');
  return ` | ${usage.puffs} ${puffText.toLowerCase()}`;
}

const UsageTime = ({ event, usage, t }) => (
  <li className="usage-time">
    {momentTimezone(usage.date).format('LT')}
    {!symptomEvent(event) && puffsDisplay(usage, t)}
  </li>
);

const UsageTimes = ({ event, t }) => (
  <ol className="usage-list">
    {event.usageList.map((usage) => (
      <UsageTime event={event} usage={usage} t={t} key={usage.id} />
    ))}
  </ol>
);

UsageTime.propTypes = {
  event: PropTypes.object.isRequired,
  usage: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

UsageTimes.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default UsageTimes;
