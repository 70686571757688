import { METRICS_REQUEST_INIT, METRICS_REQUEST_SUCCESS, METRICS_REQUEST_ERROR } from './metrics.actions';
import { REQUEST_KEYS } from '../../services/metrics.service';


const initialState = {
  isError: {
    [REQUEST_KEYS.daily_events]: null,
    [REQUEST_KEYS.rescue_by_day]: null,
    [REQUEST_KEYS.symptoms]: null,
    [REQUEST_KEYS.triggers]: null
  },
  isLoading: {
    [REQUEST_KEYS.daily_events]: false,
    [REQUEST_KEYS.monthly_trends]: false,
    [REQUEST_KEYS.symptoms]: false,
    [REQUEST_KEYS.triggers]: false
  },
  nextPage: {
    [REQUEST_KEYS.daily_events]: '',
    [REQUEST_KEYS.monthly_trends]: '',
    [REQUEST_KEYS.symptoms]: '',
    [REQUEST_KEYS.triggers]: ''
  },
  [REQUEST_KEYS.daily_events]: [],
  [REQUEST_KEYS.monthly_trends]: [],
  [REQUEST_KEYS.symptoms]: [],
  [REQUEST_KEYS.triggers]: []
};


const MetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    // the request has been lanched; set the UI state to "loading"
    case METRICS_REQUEST_INIT:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.key]: true, },
        isError: { ...state.isError, [action.key]: null }
      };

    // a successful response has been recieved;
    // add the data to existing data if the request was for a next page
    case METRICS_REQUEST_SUCCESS:
      if (!!action.pagingToken && action.pagingToken.length > 0) {
        return {
          ...state,
          isLoading: { ...state.isLoading, [action.key]: false, },
          isError: { ...state.isError, [action.key]: false },
          [action.key]: [...state[action.key]].concat(action.payload.data),
          nextPage: { ...state.nextPage, [action.key]: action.payload.nextPage },
        };
      }

      return {
        ...state,
        isLoading: { ...state.isLoading, [action.key]: false, },
        [action.key]: action.payload && action.payload.data
          ? action.payload.data
          : [],
        nextPage: { ...state.nextPage, [action.key]: action.payload ? action.payload.nextPage : '' }
      };

    // the response is an error; set the UI state to error
    case METRICS_REQUEST_ERROR:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.key]: false, },
        isError: { ...state.isError, [action.key]: true },
        [action.key]: []
      };

    default:
      return state;
  }
};

export default MetricsReducer;
