import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Tr, Th } from 'components/Tables';

import TimelineEvent from '../TimelineEvent';
import EventDate from '../EventDate';


const TimelineDay = ({ day, t, editEvent, canModify }) => {
  const { date, events } = day;
  const totalDays = events.length;
  const lastIdx = totalDays - 1;

  return events.map((event, index) => (
    <Tr
      className={classnames({
        'first-event': index === 0,
        'last-event': lastIdx === index
      })}
      key={event.id}
    >
      <Th className="event-date-header" sm={12} md={1}>
        <EventDate date={date} srOnly={index !== 0} />
      </Th>
      <TimelineEvent
        event={event}
        canModify={canModify}
        editEvent={editEvent}
        t={t}
      />
    </Tr>
  ));
};

TimelineDay.propTypes = {
  day: PropTypes.object.isRequired,
  canModify: PropTypes.bool.isRequired,
  editEvent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default TimelineDay;
