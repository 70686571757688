import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PhCheckbox from 'components/PhCheckbox';
import { SYMPTOMS } from 'services/util.service';


class SymptomOptions extends Component {
  constructor(props) {
    super(props);

    const { items } = this.props;

    this.state = {
      items,
    };

    this.addRemoveSymptom = this.addRemoveSymptom.bind(this);
  }

  addRemoveSymptom(symptom) {
    const { update } = this.props;
    const { items } = this.state;
    const i = items.indexOf(symptom);

    if (i !== -1) {
      items.splice(i, 1);
    } else {
      items.push(symptom);
    }

    update(items);
    this.setState({ items });
  }

  render() {
    const { t } = this.props;
    const { items } = this.state;

    return SYMPTOMS.map((symptom) => {
      const id = `symptom-${symptom}`;

      return (
        <PhCheckbox
          key={id}
          id={id}
          labelText={t(`SYMPTOM_${symptom.toUpperCase()}`)}
          checked={items.indexOf(symptom) !== -1}
          onChange={() => this.addRemoveSymptom(symptom)}
        />
      );
    });
  }
}

SymptomOptions.propTypes = {
  t: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  items: PropTypes.array,
};

SymptomOptions.defaultProps = {
  items: [],
};

export default withTranslation(['translations'])(SymptomOptions);
