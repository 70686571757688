import React from 'react';
import PropTypes from 'prop-types';

import DatePickerInput from 'components/DatePickerInput';
import FieldGroup from 'components/FieldGroup/FieldGroup';

import './index.scss';


const DatePickerField = ({ label, error, info, ...otherProps }) => (
  <div>
    <FieldGroup id="date-field" label={label} error={error} info={info} useBootstrapControl={false}>
      <DatePickerInput id="date-field-input" {...otherProps} />
    </FieldGroup>
  </div>
);

DatePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  info: PropTypes.string
};

DatePickerField.defaultProps = {
  error: null,
  info: null
};

export default DatePickerField;
