import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import reducersBootstrapper from './index';
import { isDevEnvironment } from '../services/util.service';


const isDevEnv = isDevEnvironment();

const crashReporter = () => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    throw err;
  }
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers = isDevEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;
/* eslint-enable no-underscore-dangle */

const reducers = combineReducers(reducersBootstrapper);
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, crashReporter)),
);

if (isDevEnv) {
  // for easier debugging
  window.store = store;
}

export default store;
