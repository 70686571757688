import { arrayOf, oneOf, number, shape, string, object } from 'prop-types';
import { planType } from 'resources/plan/plan.types';


export const USER_SESSION_OUT = 'USER/SET_SESSION_OUT';
export const USER_INIT = 'USER/REQUEST/INIT';
export const USER_ERROR = 'USER/REQUEST/ERROR';
export const USER_GET_ME_REQUEST = 'USER/GET_ME/REQUEST';
export const USER_SET_SESSION = 'USER/SET_SESSION';
export const USER_PROFILE_REQUEST = 'USER/PROFILE/REQUEST';
export const USER_PROFILE_SUCCESS = 'USER/PROFILE/SUCCESS';
export const USER_PROFILE_FAILURE = 'USER/PROFILE/FAILURE';
export const USER_GET_USER_REQUEST = 'USER/GET_USER/REQUEST';
export const USER_GET_USER_SUCCESS = 'USER/GET_USER/SUCCESS';
export const USER_ADD_FOLLOWER_REQUEST = 'USER/ADD_FOLLOWER/REQUEST';
export const USER_ADD_FOLLOWER_SUCCESS = 'USER/ADD_FOLLOWER/SUCCESS';
export const USER_DELETE_FOLLOWER_REQUEST = 'USER/DELETE_FOLLOWER/REQUEST';
export const USER_DELETE_FOLLOWER_SUCCESS = 'USER/DELETE_FOLLOWER/SUCCESS';
export const USER_DELETE_FOLLOWING_REQUEST = 'USER/DELETE_FOLLOWING/REQUEST';
export const USER_DELETE_FOLLOWING_SUCCESS = 'USER/DELETE_FOLLOWING/SUCCESS';

const followerType = shape({
  id: string,
  accessLevel: oneOf(['modify', 'view']),
  givenName: string,
  familyName: string
});

const followingType = shape({
  accessLevel: oneOf(['modify', 'view']),
  disease: string,
  familyName: string,
  givenName: string,
  id: string,
  plan: object,
  role: string,
  score: object
});

export const patientType = shape({
  age: number,
  birthDate: string,
  createdDate: string,
  disease: oneOf(['asthma', 'copd']),
  email: string,
  familyName: string,
  followers: arrayOf(followerType),
  following: arrayOf(followingType),
  givenName: string,
  group: object,
  id: string,
  language: string,
  mailingAddress: shape({
    street: string,
    street2: string,
    city: string,
    stateOrRegion: string, // eg Wisconsin
    stateCode: string, // eg WI
    postalCode: string,
    country: string
  }),
  plan: planType,
});
