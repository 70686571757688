export const symptomEvent = (event) =>
  event.source === 'manual' && !event.medication;

export const eventType = (event) => {
  if (symptomEvent(event)) {
    return 'symptom';
  }

  const { medication } = event;

  return (medication && medication.type) || null;
};

export const formFactorFor = (event) =>
  symptomEvent(event) ? 'symptom' : event.medication.formFactor;

export const hasTriggers = (event) => event.triggers && event.triggers.length > 0;
export const hasSymptoms = (event) => event.symptoms && event.symptoms.length > 0;

export const withoutAnnotation = (event) =>
  !hasTriggers(event) && !hasSymptoms(event);
