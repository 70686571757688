import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

const FieldGroup = ({
  id,
  label,
  help,
  error,
  info,
  validationState,
  children,
  useBootstrapControl,
  ...otherProps
}) => {
  const vState = validationState || error ? 'error' : '';

  return (
    <FormGroup controlId={id} className={vState}>
      {!!label && <FormLabel>{label}</FormLabel>}
      {useBootstrapControl ? (
        <FormControl {...otherProps}>{children}</FormControl>
      ) : (
        children
      )}
      {help && <div>{help}</div>}
      <p className="form-group-message error">{error}</p>
      <p className="form-group-message info">{info}</p>
    </FormGroup>
  );
};

FieldGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  info: PropTypes.string,
  validationState: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  useBootstrapControl: PropTypes.bool
};

FieldGroup.defaultProps = {
  label: null,
  help: null,
  error: null,
  info: null,
  validationState: false,
  children: null,
  useBootstrapControl: true
};

export default FieldGroup;
