import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { parseQueryString } from 'services/util.service';
import PageLayout from '../../components/PageLayout';
import PageTitle from '../../components/PageTitle';
import SubscriptionForm from './SubscriptionForm';
import VisitorHome from '../../pages/Home/VisitorHome';
import './manage-subscriptions.scss';
import { unsubscribeFromEmail } from '../../resources/group/group.controller';

const ManageSubscriptions = ({ i18n, t }) => {
  const params = parseQueryString(window.location.search);
  const [unsubscribeAll, setUnsubscribedAll] = useState('uninitialized');

  const handleUnsubscribe = (uid) => {
    unsubscribeFromEmail(uid)
      .then(() => setUnsubscribedAll('success'))
      .catch(() => setUnsubscribedAll('error'));
  };

  useEffect(() => {
    if (params?.lang && i18n.language !== params.lang) {
      i18n.changeLanguage(params.lang);
    }
  }, [i18n, i18n.language, params]);

  if (!params.uid) {
    return <VisitorHome />;
  }

  return (
    <PageTitle title={t('MANAGE_SUBSCRIPTIONS')}>
      <PageLayout title={t('MANAGE_SUBSCRIPTIONS')}>
        {t('UNSUBSCRIBE_ALL')}
        <SubscriptionForm
          t={t}
          uid={params.uid}
          handleUnsubscribe={handleUnsubscribe}
          unsubscribeAll={unsubscribeAll}
        />
      </PageLayout>
    </PageTitle>
  );
};

ManageSubscriptions.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(['subscriptions'])(ManageSubscriptions);
