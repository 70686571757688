import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import './style.scss';

const PuffSelection = ({ puffs, label = 'PUFFS', onChange, disabled, t }) => (
  <div>
    <FormGroup>
      <FormLabel htmlFor="puffs">{t(label)}</FormLabel>
      <FormControl
        as="select"
        placeholder="select"
        id="puffs"
        onChange={onChange}
        value={puffs}
        readOnly={disabled}
        className="form-select form-select-override"
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
      </FormControl>
    </FormGroup>
  </div>
);

export default PuffSelection;
