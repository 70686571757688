import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';
import 'moment/locale/ca';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/ru';
import 'moment/locale/pt';
import 'moment/locale/sv';
import 'moment/locale/de-ch';
import 'moment/locale/fr-ch';
import 'moment/locale/it-ch';


export default moment;
