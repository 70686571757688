import PropTypes from 'prop-types';
import FlexRow from 'components/FlexRow';
import { NavLink } from 'react-router-dom';
import { logout } from 'services/auth.service';
import { getSupportUrl } from 'services/config.service';
import { patientType } from 'resources/user/user.types';
import HeaderDropdown from '../HeaderDropdown/HeaderDropdown';
import './big-nav.scss';

const BigNav = ({ currentUser, me, getUserProfile, getUserById, t }) => {

  function renderLink(href, title) {
    return (
      <NavLink
        to={`/${currentUser.id}/${href}`}
        className="header-navigation-list__link"
        activeClassName="header-navigation-list__link--active"
      >
        {t(title)}
      </NavLink>
    );
  }

  return (
    <div className="big-nav no-mobile">
      <FlexRow justifyContent="space-between" className="row-max-width">
        <nav className="header-navigation header-navigation-container">
          <FlexRow container="ul" justifyContent="flex-start">
            {currentUser.plan ? (
              <>
                <li className="header-navigation-list__item">
                  {renderLink('medications', 'medications:TREATMENTS_TITLE')}
                </li>
                <li className="header-navigation-list__item">
                  {renderLink('timeline', 'TIMELINE_TITLE')}
                </li>
                <li className="header-navigation-list__item">
                  {renderLink('trends', 'TRENDS_TITLE')}
                </li>
              </>) : null}
            <li className="header-navigation-list__item">
              {renderLink('profile', 'HEADER_SETTINGS')}
            </li>
          </FlexRow>
        </nav>
        <div className="header-secondary-navigation header-navigation-container">
          <FlexRow container="ul" className="header-navigation-list">
            <li className="header-navigation-list__item">
              <a
                data-testid="support-link"
                href={getSupportUrl(currentUser.language.toLowerCase())}
                target="_blank"
                rel="noopener noreferrer"
                className="header-navigation-button header-navigation-button--transparent"
              >
                ?
              </a>
            </li>
            <li className="header-navigation-list__item">
              <HeaderDropdown
                me={me}
                getUserProfile={getUserProfile}
                getUserById={getUserById}
                currentUser={currentUser}
                logout={() => logout()}
                t={t}
              />
            </li>
          </FlexRow>
        </div>
      </FlexRow>
    </div>
  );
};

BigNav.propTypes = {
  currentUser: patientType.isRequired,
  getUserById: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  me: patientType.isRequired,
  t: PropTypes.func.isRequired,
};

export default BigNav;