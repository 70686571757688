import {
  EVENT_GET_EVENTS_REQUEST,
  EVENT_GET_EVENTS_SUCCESS,
  EVENT_GET_EVENTS_FAILURE,
  INSERT_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT
} from './event.types';


const initialState = {
  isLoading: false,
  events: [],
  error: null,
  nextPage: null,
  showNextPageButton: null
};

const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    // getEvents
    case EVENT_GET_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case EVENT_GET_EVENTS_SUCCESS:
      if (!!action.pagingToken && action.pagingToken.length > 0) {
        // get next page of existing events
        return {
          ...state,
          isLoading: false,
          events: [...state.events].concat(action.payload.data),
          nextPage: action.payload.nextPage,
          showNextPageButton: action.payload.data.length === 20
        };
      }

      // get new list of events
      return {
        ...state,
        isLoading: false,
        events: action.payload && action.payload.data
          ? action.payload.data
          : [],
        nextPage: action.payload ? action.payload.nextPage : '',
        showNextPageButton: action.payload && action.payload.data.length === 20
      };

    case EVENT_GET_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        events: []
      };

    case INSERT_EVENT:
      return {
        ...state,
        events: [...state.events, action.event]
      };

    case REPLACE_EVENT:
      return {
        ...state,
        events: state.events.map((e) => (e.id === action.event.id ? action.event : e))
      };

    case REMOVE_EVENT:
      return {
        ...state,
        events: state.events.filter((e) => e.id !== action.eventId)
      };

    default:
      return state;
  }
};

export default EventReducer;
