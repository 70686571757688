import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';


const FlexRow = ({
  children,
  alignItems,
  justifyContent,
  flexWrap,
  isAdaptive,
  className,
  container: Container,
  ...otherProps
}) => {
  const wrap = flexWrap ? 'wrap' : 'nowrap';
  const styles = {
    alignItems,
    justifyContent,
    flexWrap: wrap,
  };

  return (
    <Container
      {...otherProps}
      className={classNames('flex-row', className, isAdaptive && 'flex-row--adaptive')}
      style={styles}
    >
      {children}
    </Container>
  );
};

FlexRow.propTypes = {
  children: PropTypes.node.isRequired,
  isAdaptive: PropTypes.bool,
  className: PropTypes.string,
  container: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  alignItems: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'stretch',
    'baseline',
  ]),
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-between',
    'space-around',
  ]),
  flexWrap: PropTypes.bool,
};

FlexRow.defaultProps = {
  isAdaptive: false,
  className: null,
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: false,
  container: 'div',
};

export default FlexRow;
