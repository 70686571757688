import Keycloak from 'keycloak-js';
import { httpStatuses } from '../../constants/http-statuses';
import { deleteSession } from 'resources/user/user.actions';
import store from 'resources/store';
const constantMock = window.fetch;

let TLD = window.location.hostname.split('.').pop();
if (TLD === 'localhost') TLD = 'biz';

const redirectUri =
  window.location.hostname.split('.').pop() === 'localhost'
    ? 'http://localhost:3000'
    : `https://patient.propellerhealth.${TLD}`;

export const getKeycloakTLD = (ext) => {
  if (ext === 'eu' || ext === 'jp') return 'com';
  return ext;
};

const kc = new Keycloak({
  realm: 'ph',
  url: `https://login.propellerhealth.${getKeycloakTLD(TLD)}/auth`,
  clientId: 'ph-patient-portal'
});

window.fetch = function() {
    kc.updateToken(-1).then(refreshed => {
      if(!window.location.href.includes('.com')) {
        if(refreshed) console.log("%c The Token has been refreshed", "background:#ffff00; color:#ff0000");
        else console.log("%c The Token refresh failed!", "color:#ff0000");
      }
    }).catch(e => {
      console.warn(e);
    });
    return constantMock.apply(this, arguments);
}

export const initKeycloak = (callback) => {
  return kc
    .init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      enableLogging: true,
      promiseType: 'native',
      redirectUri: window.location.href
    })
    .then((authenticated) => {
      if (authenticated) {
        if (callback) callback();
        return true;
      }
      return false;
    })
    .catch(() => {
      kc.login({ redirectUri });
    });
};

function delete_cookie(name) {
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${domain}`;
}

function allSpecificCokie(){
  delete_cookie('ajs_user_id');
  delete_cookie('ajs_anonymous_id');
  delete_cookie('AWSALB');
  delete_cookie('AWSALBCORS');
}

export const login = () => {
  kc.login({ redirectUri });
};

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  allSpecificCokie();
  store.dispatch(deleteSession());
  kc.logout();
};

kc.onTokenExpired = () => {
  logout();
};

export const getToken = () => kc.token;

export const roles = {
  CAREGIVER: 'caregiver',
  PATIENT: 'patient'
};

export const accessLevels = {
  MODIFY: 'modify'
};

export function isUserUnauthorized(error) {
  return Boolean(error) && error.status === httpStatuses.UNAUTHORIZED;
}

export function doesCurrentUserHaveModifyAccess(me, currentUser) {
  if (!me || !currentUser) {
    return false;
  }

  if (!me.id && !currentUser.id) {
    return false;
  }

  // allow user to modify their own content
  if (me.id === currentUser.id) {
    return true;
  }

  if (!currentUser.followers || !currentUser.followers.length) {
    return false;
  }

  const currentFollower = currentUser.followers.find(
    (follower) => follower.id === me.id
  );

  if (!currentFollower) {
    return false;
  }

  return currentFollower.accessLevel === accessLevels.MODIFY;
}

export function canSendInvite(user) {
  if (!user) {
    return false;
  }

  return user.role !== roles.CAREGIVER;
}
