import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import classNames from 'classnames';
import './header-dropdown.scss';
import { patientType } from '../../../resources/user/user.types';
import profileIcon from '../../../assets/images/profile_icon.svg';

const TAB_KEY_CODE = 9;
const ELEMENT_ID = 'header-navigation-profile';
const BASE_CLASS = 'header-navigation-button';

const userInitials = (aUser) => {
  if (!aUser) {
    return 'U';
  }

  const { givenName = '', familyName = '' } = aUser;
  return givenName.substring(0, 1) + familyName.substring(0, 1);
};

class HeaderDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };

    this.onToggle = this.onToggle.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onKeyup = this.onKeyup.bind(this);
    this.selectUser = this.selectUser.bind(this);
  }

  onToggle() {
    const { isVisible } = this.state;

    this.setState({
      isVisible: !isVisible
    });
  }

  onClose() {
    this.setState({
      isVisible: false,
    });
  }

  onKeyup(event) {
    const { isVisible } = this.state;

    if (event.keyCode === TAB_KEY_CODE && !isVisible) {
      this.setState({ isVisible: true });
    }
  }


  componentDidMount() {
    document.getElementById(ELEMENT_ID).addEventListener('keyup', this.onKeyup);
  }

  componentWillUnmount() {
    document
      .getElementById(ELEMENT_ID)
      .removeEventListener('keyup', this.onKeyup);
  }

  selectUser(userId) {
    const { currentUser, me, getUserProfile, getUserById } = this.props;
    if (currentUser.id !== userId) {
      if (userId === me.id) {
        getUserProfile();
      } else {
        getUserById(userId);
      }
    }
  }

  render() {
    const { currentUser, me, logout, t } = this.props;
    const { isVisible } = this.state;

    return (
      <Dropdown
        id={ELEMENT_ID}
        className="header-navigation-dropdown"
        onToggle={this.onToggle}
        open={isVisible}
      >
        <Dropdown.Toggle
          className="user-select"
          aria-label={`${me.givenName} ${me.familyName}`} // TODO: add some decription and translation ?
        >
          <span>
            <span
              role="presentation"
              className={`${BASE_CLASS} ${BASE_CLASS}--purple ${BASE_CLASS}--lg`}
            >
              <img src={profileIcon} alt="React Logo" />
            </span>

          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu onClose={this.onClose}>
          <p className="user-select-user-name">
            {me.givenName} {me.familyName}
          </p>

          {me.following ? (
            <>
              <Dropdown.Item
                as="button"
                onClick={() => this.selectUser(me.id)}
                className={classNames('header-navigation-select-user-option', {
                  'is-active': me.id === currentUser.id
                })}
              >
                <span className="profile-name">
                  {`${me.givenName} ${me.familyName}`} ({t('HEADER_YOU')})
                </span>
              </Dropdown.Item>

              {me.following.map((following) => (
                <Dropdown.Item
                  key={following.id}
                  as="button"
                  onClick={() => this.selectUser(following.id)}
                  className={classNames(
                    'header-navigation-select-user-option',
                    { 'is-active': following.id === currentUser.id }
                  )}
                >
                  <span className="profile-name">
                    {following.givenName} {following.familyName}
                  </span>
                </Dropdown.Item>
              ))}
            </>
          ) : null}
          <Dropdown.Item
            as="button"
            className="header-navigation-select-user-option"
            onClick={() => logout()}
          >
            {t('HEADER_SIGN_OUT')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

HeaderDropdown.propTypes = {
  currentUser: patientType.isRequired,
  getUserById: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  me: patientType.isRequired,
  t: PropTypes.func.isRequired
};

export default HeaderDropdown;
