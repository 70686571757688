/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import './manage-subscriptions.scss';

const SubscriptionForm = ({ uid, handleUnsubscribe, t, unsubscribeAll }) => {
  const TLD = window.location.hostname.split('.').pop();

  return (
    <div className="manage-subscriptions">
      {unsubscribeAll === 'uninitialized' ? (
        <Button variant="link" onClick={() => handleUnsubscribe(uid)}>
          {t('UNSUBSCRIBE_ALL')}
        </Button>
      ) : null}
      {unsubscribeAll === 'success' ? (
        <p className="success">{t('SUCCESS')}</p>
      ) : null}
      {unsubscribeAll === 'error' ? (
        <p className="error">{t('ERROR')}</p>
      ) : null}
    </div>
  );
};

SubscriptionForm.propTypes = {
  handleUnsubscribe: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  unsubscribeAll: PropTypes.string.isRequired
};

export default SubscriptionForm;
