// directory structure roughly follows this concept of resources https://github.com/erikras/ducks-modular-redux
import { reducer as notifications } from 'react-notification-system-redux';
import UserReducer from './user/user.reducer';
import EventReducer from './event/event.reducer';
import GroupReducer from './group/group.reducer';
import MetricsReducer from './metrics/metrics.reducer';


const actions = {
  rUser: UserReducer,
  rEvent: EventReducer,
  rGroup: GroupReducer,
  rMetrics: MetricsReducer,
  notifications
};

export default actions; 