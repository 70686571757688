/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BsTable from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const Table = BsTable;
export const Thead = (props) => <Container as="thead" fluid {...props} />;
export const Tbody = (props) => <Container as="tbody" fluid {...props} />;
export const Tfoot = (props) => <Container as="tfoot" fluid {...props} />;
export const Tr = (props) => <Row as="tr" {...props} />;
export const Th = (props) => <Col as="th" {...props} />;
export const Td = (props) => <Col as="td" {...props} />;

export default Table;
