import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ManageSubscriptions from 'pages/ManageSubscriptions/ManageSubscriptions';
import VisitorHome from 'pages/Home/VisitorHome';
import VisitorHeader from 'components/Header/VisitorHeader';
import FooterContainer from 'components/Footer/FooterContainer';

export default function UnauthenticatedApp() {


  return (
    <main className="visitor">
      <VisitorHeader />
      <Switch>
        <Route exact path="/manage-subscriptions">
          <ManageSubscriptions />
        </Route>
        <Route path="/">
          <VisitorHome />
        </Route>
      </Switch>
      <FooterContainer />
    </main>
  );
}
