import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer as BaseToastrContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

import CloseButton from './CloseButton';
import './index.scss';


const ToastrContainer = ({ t, ...otherProps }) => (
  <BaseToastrContainer
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    closeButton={<CloseButton t={t} />}
    toastClassName="toast-container"
    {...otherProps}
  />
);

ToastrContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['translations'])(ToastrContainer);
