import {
  GROUP_GET_GROUP_REQUEST,
  GROUP_GET_GROUP_SUCCESS,
  GROUP_GET_GROUP_FAILURE
} from './group.types';


const initialState = {
  isLoading: false,
  currentGroup: null,
  error: null
};

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_GET_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case GROUP_GET_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentGroup: action.payload
      };

    case GROUP_GET_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default GroupReducer;
