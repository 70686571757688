import {
  EVENT_GET_EVENTS_REQUEST,
  EVENT_GET_EVENTS_SUCCESS,
  EVENT_GET_EVENTS_FAILURE,
  INSERT_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT
} from './event.types';
import { getEvents } from './event.controller';
import { makeActionCreator } from '../../lib/reduxUtils';


const pagingTokenFrom = (queryParams) =>
  queryParams && queryParams.pagingToken && queryParams.pagingToken.length > 0
    ? queryParams.pagingToken
    : null;

const initGetEvents = makeActionCreator(EVENT_GET_EVENTS_REQUEST);
const setEvents = makeActionCreator(EVENT_GET_EVENTS_SUCCESS, 'payload', 'pagingToken');
const getEventsError = makeActionCreator(EVENT_GET_EVENTS_FAILURE, 'error');

export const insertEvent = makeActionCreator(INSERT_EVENT, 'event');
export const replaceEvent = makeActionCreator(REPLACE_EVENT, 'event');
export const removeEvent = makeActionCreator(REMOVE_EVENT, 'eventId');

export function getEventList(userId, queryParams = {}) {
  return (dispatch) => {
    dispatch(initGetEvents());
    getEvents(userId, queryParams)
      .then((response) =>
        dispatch(setEvents(response, pagingTokenFrom(queryParams))))
      .catch((error) => {
        dispatch(getEventsError(error));
      });
  };
}
