import { makeActionCreator } from '../../lib/reduxUtils';
import {
  USER_ERROR,
  USER_GET_ME_REQUEST,
  USER_SET_SESSION,
  USER_SESSION_OUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_GET_USER_REQUEST,
  USER_GET_USER_SUCCESS,
  USER_ADD_FOLLOWER_REQUEST,
  USER_ADD_FOLLOWER_SUCCESS,
  USER_DELETE_FOLLOWER_REQUEST,
  USER_DELETE_FOLLOWER_SUCCESS,
  USER_DELETE_FOLLOWING_REQUEST,
  USER_DELETE_FOLLOWING_SUCCESS
} from './user.types';

import {
  getUser,
  patchUserById,
  getUserById as fetchUser,
  invite,
  deleteFollower as removeFollower,
  deleteFollowing as removeFollowing
} from './user.controller';



const userError = makeActionCreator(USER_ERROR, 'error');
export const setSession = makeActionCreator(USER_SET_SESSION, 'payload');
export const deleteSession = makeActionCreator(USER_SESSION_OUT);
const initGetMe = makeActionCreator(USER_GET_ME_REQUEST);
const initProfileRequest = makeActionCreator(USER_PROFILE_REQUEST);
const setUserProfile = makeActionCreator(USER_PROFILE_SUCCESS, 'payload');
const userProfileError = makeActionCreator(USER_PROFILE_FAILURE, 'error');
const initGetUser = makeActionCreator(USER_GET_USER_REQUEST);
const setUser = makeActionCreator(USER_GET_USER_SUCCESS, 'payload');
const initAddFollower = makeActionCreator(USER_ADD_FOLLOWER_REQUEST);
const followerAdded = makeActionCreator(USER_ADD_FOLLOWER_SUCCESS);
const initDeleteFollower = makeActionCreator(USER_DELETE_FOLLOWER_REQUEST);
const followerDeleted = makeActionCreator(USER_DELETE_FOLLOWER_SUCCESS);
const initDeleteFollowing = makeActionCreator(USER_DELETE_FOLLOWING_REQUEST);
const followingDeleted = makeActionCreator(USER_DELETE_FOLLOWING_SUCCESS);


export function getUserProfile() {
  return (dispatch) => {
    dispatch(initGetMe());
    getUser()
      .then((response) => dispatch(setSession(response)))
      .catch((error) => dispatch(userError(error)));
  };
}

export function updateUser(userId, profileRequest) {
  return (dispatch) => {
    dispatch(initProfileRequest());
    patchUserById(userId, profileRequest)
      .then((response) => dispatch(setUserProfile(response)))
      .catch((error) => dispatch(userProfileError(error)));
  };
}

export function getUserById(userId) {
  return (dispatch) => {
    dispatch(initGetUser());
    fetchUser(userId)
      .then((response) => dispatch(setUser(response)))
      .catch((error) => dispatch(userError(error)));
  };
}

export function addFollower(userId, followerRequest) {
  return (dispatch) => {
    dispatch(initAddFollower());
    invite(userId, followerRequest)
      .then(() => dispatch(followerAdded()))
      .catch((error) => dispatch(userError(error)));
  };
}

export function deleteFollower(userId, followerId) {
  return (dispatch) => {
    dispatch(initDeleteFollower());
    removeFollower(userId, followerId)
      .then(() => dispatch(followerDeleted()))
      .catch((error) => dispatch(userError(error)));
  };
}

export function deleteFollowing(userId, followingId) {
  return (dispatch) => {
    dispatch(initDeleteFollowing());
    removeFollowing(userId, followingId)
      .then(() => dispatch(followingDeleted()))
      .catch((error) => dispatch(userError(error)));
  };
}
