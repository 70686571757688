import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { getDefaultCaregiverPage } from 'services/config.service';
import { getSortedEventsByDate } from 'services/timeline.service';
import { Table, Thead, Tbody, Tfoot, Tr, Td } from 'components/Tables';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import PageLayout from 'components/PageLayout';
import PageTitle from 'components/PageTitle';
import TimelineHeading from './components/TimelineHeading';
import AddEventModal from './components/addEventModal/AddEventModal';
import SelectEventModal from './components/selectEventModal/SelectEventModalContainer';
import EventFormModal from './components/EventFormModal';
import TimelineDay from './components/TimelineDay';
import { trackPageView } from 'services/analytics.service';
import PhButton from 'components/PhButton/PhButton';
import './timeline.scss';


const columns = [
  'USAGE_DATE',
  'USAGE_DETAILS',
  'USAGE_TIMES',
  'USAGE_TRIGGERS_SYMPTOMS',
  'USAGE_ACTIONS'
];

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      showAddForm: null,
      showAddEventModal: false,
      showSelectEventModal: false,
      selectedEvent: null,
      medication: null,
      allowEditSymptomEvents: true
    };

    this.addEvent = this.addEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addEventForMed = this.addEventForMed.bind(this);
    this.loadMoreEvents = this.loadMoreEvents.bind(this);
  }

  componentDidMount() {
    const { match, history, getEvents, me, currentUser, currentGroup, getGroupConfig } =
      this.props;
    getGroupConfig(currentUser.group.name);
    trackPageView('timeline');
    if (currentUser) {
      getEvents(currentUser.id);
    }
    const { action } = match.params;

    if (action === 'addEvent') {
      this.setState(() => ({
        showAddEventModal: true
      }));
    }

    const allowEditSymptoms = this.isAllowEditSymptomEvents(currentUser, currentGroup);
    this.setState({
      allowEditSymptomEvents: allowEditSymptoms
    })
  }

  componentDidUpdate() {
    const { currentUser, match, history, getEvents } = this.props;

    if (!!currentUser && currentUser.id !== match.params.userId) {
      if (!currentUser.plan) {
        history.push(`/${currentUser.id}/${getDefaultCaregiverPage()}`);
      } else {
        history.push(`/${currentUser.id}/timeline`);
        getEvents(currentUser.id);
      }
    }
  }

  addEvent() {
    this.setState({
      showAddEventModal: true
    });
  }

  editEvent(event) {
    const eventType = event.medication ? event.medication.type : 'symptom';
    switch (eventType) {
      case 'rescue':
      case 'symptom':
        this.setState({
          showAddForm: true,
          type: eventType,
          selectedEvent: event
        });
        break;

      case 'controller':
        if (event.usageList.length === 1) {
          this.setState({
            showAddForm: true,
            type: eventType,
            showSelectEventModal: false, // to hide select modal upon editing single usage
            selectedEvent: event
          });
        } else {
          // for selecting usage within grouped controller event
          this.setState({
            showSelectEventModal: true,
            selectedEvent: event
          });
        }
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn('invalid event type', eventType);
    }
  }

  handleClose() {
    this.setState({
      showAddEventModal: false,
      showSelectEventModal: false,
      showAddForm: false,
      selectedEvent: null,
      medication: null
    });
  }

  addEventForMed(medication) {
    const type =
      medication && medication.medication
        ? medication.medication.type
        : 'symptom';

    this.setState({
      showAddForm: true,
      showAddEventModal: false,
      type,
      medication
    });
  }

  loadMoreEvents() {
    const { nextPage, currentUser, getEvents } = this.props;
    getEvents(currentUser.id, {
      pagingToken: nextPage
    });
  }

  isAllowEditSymptomEvents(patient, group) {
    const trialArms = group?.clinicalTrial?.arms;

    if (trialArms && patient.clinicalTrial) {
      const patientArmId = patient.clinicalTrial.clinicalTrialArmId;
      const armConfig = trialArms.find((arm) => arm.id === patientArmId);

      return armConfig.allowEditSymptomEvents;
    }

    return true;

  }

  render() {
    const {
      t,
      events,
      isLoading,
      isInitialLoad,
      showNextPageButton,
      doesCurrentUserHaveModifyAccess,
      currentUser
    } = this.props;

    const {
      showAddEventModal,
      showAddForm,
      type,
      showSelectEventModal,
      selectedEvent,
      medication,
      allowEditSymptomEvents
    } = this.state;

    const daysWithEvents = getSortedEventsByDate(events);
    const hasEvents = daysWithEvents.length > 0;
    const title = t('TIMELINE_TITLE');

    return (
      <PageTitle title={t('TIMELINE_PAGE_TITLE')}>
        <PageLayout
          title={title}
          heading={
            <TimelineHeading
              title={title}
              userId={currentUser.id}
              isLoading={isLoading}
              canModify={doesCurrentUserHaveModifyAccess}
              addEvent={this.addEvent}
              t={t}
            />
          }
        >
          <div className="timeline-table-wrapper">
            {isInitialLoad && <LoadingSpinner />}
            {!isInitialLoad && hasEvents ? (
              <Table className="timeline-table">
                <Thead>
                  <Tr>
                    {columns.map((c) => (
                      <th key={c} scope="col">
                        <h2>{t(c)}</h2>
                      </th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {daysWithEvents.map((day) => (
                    <TimelineDay
                      key={day.date}
                      day={day}
                      canModify={doesCurrentUserHaveModifyAccess}
                      editEvent={this.editEvent}
                      t={t}
                    />
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Td colSpan={columns.length}>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        !showNextPageButton ? (
                          'all events loaded'
                        ) : isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <div className="text-center">
                            <PhButton onClick={this.loadMoreEvents}>
                              {t('LOAD_MORE')}
                            </PhButton>
                          </div>
                        )
                      }
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            ) : (
              <p>{t('NO_EVENTS_MESSAGE')}</p>
            )}
          </div>
        </PageLayout>

        {showAddEventModal && (
          <AddEventModal
            onHide={this.handleClose}
            plan={currentUser.plan}
            selectMedication={this.addEventForMed}
            show={showAddEventModal}
            allowEditSymptomEvents={allowEditSymptomEvents}
            t={t}
          />
        )}

        <Modal
          dialogClassName="timeline-modal"
          show={showAddForm}
          onHide={this.handleClose}
        >
          <EventFormModal
            close={this.handleClose}
            event={selectedEvent}
            medication={medication}
            type={type}
          />
        </Modal>

        {showSelectEventModal && (
          <SelectEventModal
            show={showSelectEventModal}
            onHide={this.handleClose}
            onSelect={(event) => this.editEvent(event)}
            selectedEvent={selectedEvent}
          />
        )}
      </PageTitle>
    );
  }
}

Timeline.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInitialLoad: PropTypes.bool.isRequired,
  doesCurrentUserHaveModifyAccess: PropTypes.bool.isRequired,
  getEvents: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  notifyInfo: PropTypes.func.isRequired,
  getGroupConfig: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showNextPageButton: PropTypes.bool,
  nextPage: PropTypes.string,
  currentGroup: PropTypes.object
};

Timeline.defaultProps = {
  nextPage: null,
  showNextPageButton: false,
  currentGroup: null
};

export default Timeline;
