import React from 'react';
import PropTypes from 'prop-types';
import './PhInputLabelText.scss';

const PhInputLabelText = ({ children, isDisabled = false }) => {
  const disabled = isDisabled ? 'disabled' : '';
  return (
    <span className={`ph-input-label-text ${disabled}`.trim()}>{children}</span>
  );
};

PhInputLabelText.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool
};

export default PhInputLabelText;
