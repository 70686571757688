import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import moment from 'services/moment-timezone.service';
import { localTime } from 'services/moment.service';
import {
  isSensorEvent,
  isObjectEmpty,
  TIMESTAMP_FORMAT
} from 'services/util.service';
import { hasTriggers, hasSymptoms } from 'services/events.service';
import {
  deleteEvent,
  updateEvent,
  createEvent,
  getEventById
} from 'resources/event/event.controller';

import EventForm from '../EventForm';
import container from './container';


function defaultEventForType(anEvent, aType, aMedication) {
  if (anEvent) return anEvent; // editing

  switch (aType) {
    case 'symptom':
      return {
        symptoms: [],
        triggers: [],
        type: 'symptom'
      };

    case 'rescue':
      return {
        medicationId: aMedication.medication.id,
        preemptive: false,
        symptoms: [],
        triggers: [],
        type: 'rescue',
        unitDoses: 1,
      };

    case 'controller':
      return {
        medicationId: aMedication.medication.id,
        type: 'controller',
        unitDoses: 1,
      };

    default:
      throw new Error(`Unknown type ${aType} for defaultEventForType`);
  }
}

const EVENT_ALLOWED_ATTRIBUTES = [
  'preemptive',
  'symptoms',
  'triggers',
  'unitDoses',
];
const ADDRESS_DISALLOWED_ATTRIBUTES = [
  'altitude',
  'formatted',
  'precisionVertical'
];

class EventFormModal extends React.Component {
  constructor(props) {
    super(props);
    const { event, type, medication } = props;

    this.state = {
      event: defaultEventForType(event, type, medication),
      dateTime: event
        ? localTime(event.date, TIMESTAMP_FORMAT)
        : moment().format(TIMESTAMP_FORMAT),
      isSaving: false,
      changeDetected: false,
      dateError: false
    };

    this.setDate = this.setDate.bind(this);
    this.setIsPreemptive = this.setIsPreemptive.bind(this);
    this.setPuffs = this.setPuffs.bind(this);
    this.updateListType = this.updateListType.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  setDate(value) {
    const { t } = this.props;

    this.setState({
      dateTime: value,
      changeDetected: true,
      dateError: !moment(value).isBefore() ? t('FUTURE_EVENT_ERROR') : false
    });
  }

  updateEventState(key, value) {
    const { event } = this.state;

    this.setState({
      event: {
        ...event,
        [key]: value
      },
      changeDetected: true
    });
  }

  updateListType(list) {
    return (values) => this.updateEventState(list, values);
  }

  setIsPreemptive() {
    const { event } = this.state;

    this.updateEventState('preemptive', !event.preemptive);
  }

  setPuffs({ target }) {
    this.updateEventState('unitDoses', parseInt(target.value, 10));
  }

  createEvent() {
    const { uid, insertEvent } = this.props;
    const { event, dateTime } = this.state;

    this.setState({
      changeDetected: false,
      isSaving: true
    });

    return createEvent(uid, { ...event, date: localTime(dateTime) })
      .then((res) => getEventById(uid, res.id))
      .then((newEvent) => insertEvent(newEvent));
  }

  propsForUpdate(anEvent, aDate) {
    // sets props for type (just use a whitelist for now)
    // updates the time if not a sensored event
    // deletes event.address.formatted if it exists

    const result = EVENT_ALLOWED_ATTRIBUTES.reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = anEvent[key];
      return obj;
    }, {});

    if (!isSensorEvent(anEvent)) {
      result.date = localTime(aDate);
      if (anEvent.type !== 'symptom') result.medicationId = anEvent.medication.id;
    }

    if (anEvent.address && !isObjectEmpty(anEvent.address)) {
      const address = { ...anEvent.address };
      // ADDRESS_DISALLOWED_ATTRIBUTES fields are allowed according to
      // the documentation, but PUT requests fail with them. We could
      // create an allowed list as we do for other properties, but
      // I don't know the impact of neglecting to include a property.
      ADDRESS_DISALLOWED_ATTRIBUTES.forEach((attr) => delete address[attr]);
      result.address = address;
    }

    return result;
  }

  updateEvent() {
    const { uid, replaceEvent } = this.props;
    const { event, dateTime } = this.state;

    const data = this.propsForUpdate(event, dateTime);

    return updateEvent(uid, event.id, data)
      .then(() => getEventById(uid, event.id))
      .then((evt) => replaceEvent(evt));
  }

  saveChanges(e) {
    e.preventDefault();
    const { close } = this.props;
    const { event } = this.state;

    if (!this.formIsValid()) return;

    const saveMethod = event.id ? this.updateEvent : this.createEvent;
    saveMethod().then(close);
  }

  deleteEvent() {
    const {
      t,
      uid,
      event: { id: eid },
      removeEvent,
      close
    } = this.props;

    // show wip?
    if (window.confirm(t('CONFIRM_EVENT_DELETION'))) {
      deleteEvent(uid, eid).then(() => {
        removeEvent(eid);
        close();
      });
    }
  }

  isValid(anEvent, aDate, aType) {
    switch (aType) {
      case 'symptom':
        return (
          moment(aDate).isBefore()
          && (hasTriggers(anEvent) || hasSymptoms(anEvent))
        );

      case 'controller':
      case 'rescue':
        return (
          moment(aDate).isBefore() && (anEvent.medication?.id || anEvent?.medicationId) && anEvent.unitDoses
        );

      default:
        throw new Error(`Unknown type ${aType} for isValid`);
    }
  }

  formIsValid() {
    const { type } = this.props;
    const { event, dateTime, changeDetected, isSaving } = this.state;

    if (isSaving) {
      return false;
    }

    if (event.id) {
      // editing
      return changeDetected && this.isValid(event, dateTime, type);
    }
    // adding event
    return dateTime.length > 0 && this.isValid(event, dateTime, type);
  }

  render() {
    const { type, medication, t, close } = this.props;
    const { event, dateTime, dateError } = this.state;

    // we need to support cases where the med is no longer on the plan.
    // if our event has a medication, because we're editing, use it
    // if we're creating, use what's passed in.
    // fallback to an empty object because as the modal is being removed,
    // we may have nothing else
    const med = event.medication || (medication && medication.medication) || {};

    return (
      <EventForm
        event={cloneDeep(event)}
        medication={med}
        dateTime={dateTime}
        setDate={this.setDate}
        dateError={dateError}
        setPuffs={this.setPuffs}
        setPreemptive={this.setIsPreemptive}
        onSubmit={this.saveChanges}
        updateList={this.updateListType}
        deleteEvent={this.deleteEvent}
        type={type}
        close={close}
        t={t}
      />
    );
  }
}

EventFormModal.propTypes = {
  close: PropTypes.func.isRequired,
  event: PropTypes.object,
  insertEvent: PropTypes.func.isRequired,
  medication: PropTypes.object.isRequired,
  removeEvent: PropTypes.func.isRequired,
  replaceEvent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

EventFormModal.defaultProps = {
  event: {}
};

export default container(EventFormModal);
