import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Player from '@vimeo/player';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageLayout from 'components/PageLayout';
import SupportSidebar from './SupportSidebar';
import { useHistory } from 'react-router-dom';

export default function VisitorHome() {
  const { i18n, t } = useTranslation('education');
  const language = i18n.language ? (i18n.language.toLowerCase() === 'en' ? 'en-US' : i18n.language) : 'en-US';
  const videoId = '224359133';
  const history = useHistory();

  function setUpPlayer() {
    try{
      const player = new Player('video-container', {
        id: videoId,
        height: 380,
        texttrack: language,
        width: '100%'
      });
      player.enableTextTrack(language);
    }catch(e){
      console.log('Video setup error', e);
    }
  }

  useEffect(() => {
    history?.push('/');
    setUpPlayer();
  }, []);

  return (
    <PageLayout hasHeader={false}>
      <Row>
        <Col xs={12} md={9}>
          <div className="embed-responsive embed-responsive-16by9 article">
            <iframe
              id="video-container"
              className="embed-responsive-item"
              data-testid="video-container"
              src={`https://player.vimeo.com/video/${videoId}`}
              height="380"
              style={{ border: 'none' }}
              title={t('VISITOR_HEADLINE_1')}
              width="100%"
            ></iframe>
          </div>
          <h1 className="article-title">{t('VISITOR_HEADLINE_1')}</h1>
          <div className="article-divider"></div>
          <div className="article-text">
            <p>{t('VISITOR_PARAGRAPH_1')}</p>
            <p>{t('VISITOR_PARAGRAPH_2')}</p>
            <ul className="bullets">
              <li>{t('LIST_ITEM_1')}</li>
              <li>{t('LIST_ITEM_2')}</li>
              <li>{t('LIST_ITEM_3')}</li>
              <li>{t('LIST_ITEM_4')}</li>
              <li>{t('LIST_ITEM_5')}</li>
            </ul>
            <p>{t('VISITOR_PARAGRAPH_3')}</p>
            <h2 className="article-heading">{t('VISITOR_HEADLINE_2')}</h2>
            <p>{t('VISITOR_PARAGRAPH_4')}</p>
          </div>
        </Col>
        <Col xs={12} md={3}>
          <SupportSidebar language={language} />
        </Col>
      </Row>
    </PageLayout>
  );
}
