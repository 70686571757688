import React from 'react';
import PropTypes from 'prop-types';
import PhSelect from 'components/PhSelect/PhSelect';

const MedicationOptions = ({ t, value, options, disabled, onChange }) => (
  <PhSelect
    label={t('MEDICATION')}
    name="medication"
    id="medication"
    value={value}
    isDisabled={disabled || !options || options.length < 2}
    onChange={onChange}
    options={options.map((o) => ({ name: o.name, value: o.id }))}
  />
);
MedicationOptions.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.string
};

MedicationOptions.defaultProps = {
  disabled: false,
  value: ''
};

export default MedicationOptions;
