import { getTLD } from '../services/util.service';

const DEV_WRITE_KEY = 'I3PkCPSZtpUL0gMtpX0OpDX5xT346U48';
const TEST_WRITE_KEY = 'sdb2J8LW8DQu9YyD404HQCNvlXBjkmjK';
const PROD_WRITE_KEY = 'qpL0EiroFS34LnsJ7qZreFkSXlQvrlvE';
const EU_WRITE_KEY = '3DklszOGkzVzPO6g7zoji4TB9BVzCQIn';
const JP_WRITE_KEY = '7zcZUgP8wPrdWfBvpdI9ADbe8u6err0k';

const TLD = getTLD(window.location);
let session_uuid;

export function identify(mongoObjectId) {
  if (window && window.analytics) {
    window.analytics.identify(
      mongoObjectId,
      { session_uuid },
      { context: { ip: '0.0.0.0' } }
    );
  }
}

function getWriteKey(TLD) {
  switch (TLD) {
    case 'com':
      return PROD_WRITE_KEY;
    case 'io':
      return TEST_WRITE_KEY;
    case 'biz':
      return DEV_WRITE_KEY;
    case 'eu':
      return EU_WRITE_KEY;
    case 'jp':
      return JP_WRITE_KEY;
    default:
      return DEV_WRITE_KEY;
  }
}

export function trackPageView(pageName) {
  if (window?.analytics) {
    window.analytics.page(
      pageName,
      {
        session_uuid
      },
      { context: { ip: '0.0.0.0' } }
    );
  }
}

// event:string, properties:object
export function trackEvent(event, properties) {
  if (window?.analytics) {
    window.analytics.track(
      event,
      { session_uuid: session_uuid, ...properties },
      { context: { ip: '0.0.0.0' } }
    );
  }
}

export function initSegment({ userId, sessionUuid, page }) {
  session_uuid = sessionUuid;
  const write_key = getWriteKey(TLD);
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = write_key;
      analytics.SNIPPET_VERSION = '4.13.2';
      analytics.load(write_key);
      if (userId) {
        identify(userId);
        trackPageView(page || 'home-news');
      }
    }
}
