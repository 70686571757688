import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';


const HeaderControls = ({ userId, canModify, addEvent, t }) => (
  <>
    <Link id="timeline-sensor-sync" to={`/${userId}/medications`}>
      {t('TIMELINE_SENSOR_SYNC')}
    </Link>
    {canModify && (
      <Button onClick={addEvent} variant="primary">
        {t('ADD')}
      </Button>
    )}
  </>
);

export default HeaderControls;
