import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Footer from './Footer';


const mapStateToProps = ({ rUser }) => ({
  isUserLoggedIn: !!rUser.me
});

export default compose(
  withTranslation(['translations']),
  connect(mapStateToProps)
)(Footer);
