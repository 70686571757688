import i18n from 'i18n';
import {
  USER_INIT,
  USER_SESSION_OUT,
  USER_ERROR,
  USER_GET_ME_REQUEST,
  USER_SET_SESSION,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_GET_USER_REQUEST,
  USER_GET_USER_SUCCESS,
  USER_ADD_FOLLOWER_REQUEST,
  USER_ADD_FOLLOWER_SUCCESS,
  USER_DELETE_FOLLOWER_REQUEST,
  USER_DELETE_FOLLOWER_SUCCESS,
  USER_DELETE_FOLLOWING_REQUEST,
  USER_DELETE_FOLLOWING_SUCCESS
} from './user.types';


const initialState = {
  isLoading: false,
  me: null,
  error: null,
  notifications: [],
  changePasswordSuccess: null,
  updateUserProfileSuccess: null,
  currentUser: null,
  followerAdded: null,
  followerDeleted: null,
  followingDeleted: null,
  sensorSearchResult: null
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SESSION_OUT:

      return {
        ...state,
        ...initialState
      };

    case USER_INIT:

      return {
        ...state,
        isLoading: true,
        error: null
      };

    case USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    // getUser
    case USER_GET_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        updateUserProfileSuccess: null,
        followerAdded: null,
        followerDeleted: null,
        followingDeleted: null
      };

    // signIn
    case USER_SET_SESSION:
      i18n.changeLanguage(action.payload?.language || 'en-US');
      return {
        ...state,
        isLoading: false,
        me: action.payload,
        currentUser: action.payload
      };

    // update profile
    case USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        updateUserProfileSuccess: null
      };

    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateUserProfileSuccess: true
      };

    case USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        updateUserProfileSuccess: false
      };

    // getUserById
    case USER_GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        updateUserProfileSuccess: null,
        followerAdded: null,
        followerDeleted: null,
        followingDeleted: null
      };

    case USER_GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload
      };

    // add follower
    case USER_ADD_FOLLOWER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        followerAdded: null
      };

    case USER_ADD_FOLLOWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        followerAdded: true
      };

    // delete follower
    case USER_DELETE_FOLLOWER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        followerDeleted: null
      };

    case USER_DELETE_FOLLOWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        followerDeleted: true
      };

    // delete following
    case USER_DELETE_FOLLOWING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        followingDeleted: null
      };

    case USER_DELETE_FOLLOWING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        followingDeleted: true
      };

    default:
      return state;
  }
};

export default UserReducer;
