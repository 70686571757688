import {
  getApiUrl,
  getApiHeaders,
  checkAuthorizedResponse,
  extractJSON
} from '../../services/request.service';


export const fetchMetrics = (userId, queryParams) => {
  let queryString = '';

  if (queryParams) {
    queryString = Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&');
  }
  return window.fetch(`${getApiUrl()}/users/${userId}/metrics?${queryString}`, {
    method: 'GET',
    headers: {
      ...getApiHeaders()
    },
    credentials: 'include'
  })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
};
