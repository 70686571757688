import React from 'react';
import PropTypes from 'prop-types';

const Caret = ({
  alt = 'arrow',
  className = '',
  color = '#000000',
  width = '16'
}) => (
  <svg
    aria-labelledby="title"
    className={`icon caret ${className}`}
    fill={color}
    version="1.1"
    viewBox="0 0 14 16"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="title">{alt}</title>
    <g transform="translate(-655.000000, -965.000000)">
      <g transform="translate(660.000000, 973.000000) rotate(-90.000000) translate(-660.000000, -973.000000) translate(652.000000, 965.000000)">
        <rect fill="#FFFFFF" opacity="0" x="1.13686838e-13" y="0"></rect>
        <polygon points="7.94496 12.6903333 1.13686838e-13 4.41433333 1.35744 3.00033333 7.94496 9.862 14.53248 3 15.88992 4.414"></polygon>
      </g>
    </g>
  </svg>
);

Caret.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string
};

export default Caret;
