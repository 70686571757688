import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


const MedicationIcon = ({ className, type, formFactor }) => (
  <div
    className={`medication-icon medication-type-${type} medication-form-factor-${formFactor} ${className}`}
  >
    &nbsp;
  </div>
);

MedicationIcon.propTypes = {
  className: PropTypes.string,
  formFactor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

MedicationIcon.defaultProps = {
  className: ''
};

export default MedicationIcon;
