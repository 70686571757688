import React, { useEffect, useRef, useState } from 'react';

const OptionsList = ({
  name,
  options,
  selectOption,
  selectedOption,
  toggleOptions
}) => {
  const [activeOption, setActiveOption] = useState(
    selectedOption || options[0]?.value
  );

  const optionRef = useRef();

  useEffect(() => {
    optionRef.current?.focus();
  }, [optionRef.current]);

  function handleClick(value) {
    setActiveOption(value);
    if (selectOption) {
      selectOption(value);
    }
  }

  function handleKeyPress(e) {
    const target = e.target;
    const currentIndex = Number(target.getAttribute('data-index'));

    if (target?.className === 'listbox-toggle') {
      optionRef.current?.focus();
    }

    if (
      target.className !== 'option' ||
      currentIndex < 0 ||
      currentIndex > options.length
    ) {
      if (toggleOptions) {
        toggleOptions();
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        if (currentIndex < options.length - 1) {
          const value = options[currentIndex + 1].value;
          setActiveOption(value);
          optionRef.current = target.nextElementSibling;
        }
        break;
      case 'ArrowUp':
        if (currentIndex - 1 >= 0) {
          setActiveOption(options[currentIndex - 1].value);
          optionRef.current = target.previousElementSibling;
        }
        break;
      case ' ':
      case 'Enter':
        selectOption(activeOption);
        break;
      default:
        toggleOptions();
    }
  }

  return (
    <ul
      aria-labelledby={`select-${name}`}
      className="option-list"
      onKeyDown={handleKeyPress}
      role="listbox"
      title={name}
    >
      {options.length > 0 ? (
        options.map((option, index) => (
          <li
            className="option"
            data-index={index}
            data-value={option.value}
            id={String(option.value)}
            key={option.value}
            onClick={() => handleClick(option.value)}
            onFocus={() => setActiveOption(option.value)}
            ref={option.value === activeOption ? optionRef : null}
            role="option"
            tabIndex={-1}
          >
            {option.image ? (
              <img src={option.image} alt={option.alt || ''} />
            ) : null}
            {option.name}
          </li>
        ))
      ) : (
        <li></li>
      )}
    </ul>
  );
};

export default OptionsList;
