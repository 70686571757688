import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import './ControlPatient.scss';


const ControlPatient = ({ t }) => { return (<><h1 className='control_header'>{t('TRIAL_BLIDING_HEADER')}</h1><br /><PageLayout heading={t('TRIAL_BLIDING_BODY')} /> </>); }

export default withTranslation(['translations'])(ControlPatient);

ControlPatient.propTypes = {
    t: PropTypes.func.isRequired
};
