import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SelectEventModal from './SelectEventModal';


const mapStateToProps = ({ rEvent: { events } }) => ({ events });

export default compose(
  withTranslation(['translations', 'timeline']),
  connect(mapStateToProps)
)(SelectEventModal);
