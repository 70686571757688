import {
  getApiUrl,
  getApiHeaders,
  checkAuthorizedResponse,
  extractJSON
} from '../../services/request.service';


export function getEvents(userId, queryParams) {
  let queryString = '';

  if (queryParams) {
    queryString = Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&');
  }
  return window
    .fetch(`${getApiUrl()}/users/${userId}/events?${queryString}`, {
      method: 'GET',
      headers: {
        ...getApiHeaders()
      },
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function createEvent(userId, eventRequest) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/events`, {
      method: 'POST',
      headers: {
        ...getApiHeaders()
      },
      body: JSON.stringify(eventRequest),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function updateEvent(userId, eventId, eventRequest) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/events/${eventId}`, {
      method: 'PUT',
      headers: {
        ...getApiHeaders()
      },
      body: JSON.stringify(eventRequest),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function deleteEvent(userId, eventId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/events/${eventId}`, {
      method: 'DELETE',
      headers: {
        ...getApiHeaders()
      },
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function getEventById(userId, eventId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/events/${eventId}`, {
      method: 'GET',
      headers: {
        ...getApiHeaders()
      },
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

const EventController = {
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  getEventById
};

export default EventController;
