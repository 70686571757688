import {
  getApiUrl,
  getApiHeaders,
  checkAuthorizedResponse,
  checkUnauthorizedResponse,
  extractJSON
} from '../../services/request.service';

export function signIn(email, password) {
  return window
    .fetch(`${getApiUrl()}/users/signIn`, {
      method: 'POST',
      headers: getApiHeaders(),
      body: JSON.stringify({ email, password }),
      credentials: 'include'
    })
    .then(checkUnauthorizedResponse)
    .then(extractJSON);
}

export function getUser() {
  return window
    .fetch(`${getApiUrl()}/users/me`, {
      method: 'GET',
      headers: getApiHeaders()
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function getUserContent(userId, lang) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/content/library?language=${lang}`, {
      method: 'GET',
      headers: getApiHeaders(),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function patchUserById(userId, data) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}`, {
      method: 'PATCH',
      headers: getApiHeaders(),
      body: JSON.stringify(data),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function getUserById(userId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}`, {
      method: 'GET',
      headers: getApiHeaders(),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function invite(userId, followerRequest) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/followers`, {
      method: 'POST',
      headers: getApiHeaders(),
      body: JSON.stringify(followerRequest),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function deleteFollower(userId, followerId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/followers/${followerId}`, {
      method: 'DELETE',
      headers: getApiHeaders(),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function deleteFollowing(userId, followingId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/following/${followingId}`, {
      method: 'DELETE',
      headers: getApiHeaders(),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function sendResetPassword(email) {
  return window
    .fetch(`${getApiUrl()}/users/lostPassword`, {
      method: 'POST',
      headers: getApiHeaders(),
      body: JSON.stringify({ email }),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse);
}

export function searchUserBySensor(macAddress) {
  return window
    .fetch(`${getApiUrl()}/users/search/sensor?mac=${macAddress}`, {
      method: 'GET',
      headers: getApiHeaders(),
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}
