import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { insertEvent, replaceEvent, removeEvent } from 'resources/event/event.actions';


const stateProps = ({ rUser: { currentUser } }) => ({
  uid: currentUser.id
});

const actionCreators = {
  insertEvent,
  replaceEvent,
  removeEvent
};

export default compose(
  withTranslation(['translations', 'timeline']),
  connect(stateProps, actionCreators)
);
