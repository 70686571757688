import moment from '../moment-timezone.service';
import { getApiDomain } from 'services/request.service';

const codesAndNames = (item) => {
  const [value, name] = item.split('|');
  return { value, name };
};

const regionCodesAndNames = (value) => {
  const displayName = value.split('|')[2];
  const result = codesAndNames(value);

  result.displayName = displayName || result.name;

  return result;
};

const CANADA_PROVINCES = [
  'AB|Alberta',
  'BC|British Columbia',
  'MB|Manitoba',
  'NB|New Brunswick',
  'NL|Newfoundland and Labrador',
  'NT|Northwest Territories',
  'NS|Nova Scotia',
  'NU|Nunavut',
  'ON|Ontario',
  'PE|Prince Edward Island',
  'QC|Quebec',
  'SK|Saskatchewan',
  'YT|Yukon Territories'
];

const ITALIAN_REGIONS = [
  'AG|Agrigento',
  'AL|Alessandria',
  'AN|Ancona',
  'AO|Aosta',
  'AR|Arezzo',
  'AP|Ascoli Piceno',
  'AT|Asti',
  'AV|Avellino',
  'BA|Bari',
  'BT|Barletta-Andria-Trani',
  'BL|Belluno',
  'BN|Benevento',
  'BG|Bergamo',
  'BI|Biella',
  'BO|Bologna',
  'BZ|Bolzano',
  'BS|Brescia',
  'BR|Brindisi',
  'CA|Cagliari',
  'CL|Caltanissetta',
  'CB|Campobasso',
  'CI|Carbonia-Iglesias',
  'CE|Caserta',
  'CT|Catania',
  'CZ|Catanzaro',
  'CH|Chieti',
  'SCV|Vatican City|Città del Vaticano',
  'CO|Como',
  'CS|Cosenza',
  'CR|Cremona',
  'KR|Crotone',
  'CN|Cuneo',
  'EN|Enna',
  'FM|Fermo',
  'FE|Ferrara',
  'FI|Florence',
  'FG|Foggia',
  'FC|Forlì-Cesena',
  'FR|Frosinone',
  'GE|Genoa',
  'GO|Gorizia',
  'GR|Grosseto',
  'IM|Imperia',
  'IS|Isernia',
  'SP|La Spezia',
  "AQ|L'Aquila",
  'LT|Latina',
  'LE|Lecce',
  'LC|Lecco',
  'LI|Livorno',
  'LO|Lodi',
  'LU|Lucca',
  'MC|Macerata',
  'MN|Mantua',
  'MS|Massa and Carrara|Massa-Carrara',
  'MT|Matera',
  'VS|Medio Campidano',
  'ME|Messina',
  'MI|Milan',
  'MO|Modena',
  'MB|Monza and Brianza|Monza e della Brianza',
  'NA|Naples',
  'NO|Novara',
  'NU|Nuoro',
  'OG|Ogliastra',
  'OT|Olbia-Tempio',
  'OR|Oristano',
  'PD|Padua',
  'PA|Palermo',
  'PR|Parma',
  'PV|Pavia',
  'PG|Perugia',
  'PU|Pesaro and Urbino|Pesaro e Urbino',
  'PE|Pescara',
  'PC|Piacenza',
  'PI|Pisa',
  'PT|Pistoia',
  'PN|Pordenone',
  'PZ|Potenza',
  'PO|Prato',
  'RG|Ragusa',
  'RA|Ravenna',
  'RC|Reggio Calabria',
  'RE|Reggio Emilia',
  'RSM|Repubic of San Marino|Repubblica di San Marino',
  'RI|Rieti',
  'RN|Rimini',
  'RM|Rome',
  'RO|Rovigo',
  'SA|Salerno',
  'SS|Sassari',
  'SV|Savona',
  'SI|Siena',
  'SO|Sondrio',
  'SR|Syracuse',
  'TA|Taranto',
  'TE|Teramo',
  'TR|Terni',
  'TP|Trapani',
  'TN|Trento',
  'TV|Treviso',
  'TS|Trieste',
  'TO|Turin',
  'UD|Udine',
  'VA|Varese',
  'VB|Verbano-Cusio-Ossola',
  'VC|Vercelli',
  'VE|Venice',
  'VR|Verona',
  'VV|Vibo Valentia',
  'VI|Vicenza',
  'VT|Viterbo'
];

const US_STATES = [
  'AL|Alabama',
  'AK|Alaska',
  'AZ|Arizona',
  'AR|Arkansas',
  'CA|California',
  'CO|Colorado',
  'CT|Connecticut',
  'DE|Delaware',
  'DC|District of Columbia',
  'FL|Florida',
  'GA|Georgia',
  'HI|Hawaii',
  'ID|Idaho',
  'IL|Illinois',
  'IN|Indiana',
  'IA|Iowa',
  'KS|Kansas',
  'KY|Kentucky',
  'LA|Louisiana',
  'ME|Maine',
  'MD|Maryland',
  'MA|Massachusetts',
  'MI|Michigan',
  'MN|Minnesota',
  'MS|Mississippi',
  'MO|Missouri',
  'MT|Montana',
  'NE|Nebraska',
  'NV|Nevada',
  'NH|New Hampshire',
  'NJ|New Jersey',
  'NM|New Mexico',
  'NY|New York',
  'NC|North Carolina',
  'ND|North Dakota',
  'OH|Ohio',
  'OK|Oklahoma',
  'OR|Oregon',
  'PA|Pennsylvania',
  'RI|Rhode Island',
  'SC|South Carolina',
  'SD|South Dakota',
  'TN|Tennessee',
  'TX|Texas',
  'UT|Utah',
  'VT|Vermont',
  'VA|Virginia',
  'WA|Washington',
  'WV|West Virginia',
  'WI|Wisconsin',
  'WY|Wyoming'
];

const LOCALES = [
  'ca-ES|Català',
  'cs-CZ|čeština',
  'da-DK|Dansk',
  'de-AT|Deutsch (AT)',
  'de-BE|Deutsch (BE)',
  'de-CH|Deutsch (CH)',
  'de-DE|Deutsch (DE)',
  'el-GR|Ελληνικά',
  'en-AU|English (AU)',
  'en-CA|English (CA)',
  'en-GB|English (GB)',
  'en-IE|English (IE)',
  'en-NO|English (NO)',
  'en-US|English (US)',
  'es-AR|Español (AR)',
  'es-ES|Español (ES)',
  'es-MX|Español (MX)',
  'es-US|Español (US)',
  'fi-FI|Suomi (FI)',
  'fr-BE|Français (BE)',
  'fr-CA|Français (CA)',
  'fr-CH|Français (CH)',
  'fr-FR|Français (FR)',
  'it-IT|Italiano (IT)',
  'it-CH|Italiano (CH)',
  'ja-JP|日本語',
  'ko-KR|한국어',
  'nb-NO|norsk bokmål',
  'nl-BE|Nederlands (BE)',
  'nl-NL|Nederlands (NL)',
  'pt-PT|Português (PT)',
  'ru-RU|Pусский',
  'sv-FI|Svenska (FI)',
  'sv-SE|Svenska (SE)'
];

const REGIONS = {
  CA: CANADA_PROVINCES,
  IT: ITALIAN_REGIONS,
  US: US_STATES
};

const GENDERS = ['female|FEMALE', 'male|MALE'];

export const availableLocales = LOCALES.map(codesAndNames);

export const availableGenders = GENDERS.map(codesAndNames);

export function regionsForCountry(countryCode) {
  if (!countryCode || typeof countryCode !== 'string') {
    // eslint-disable-next-line no-console
    console.warn('Invalid countryCode', countryCode);
    return [];
  }

  const regions = REGIONS[countryCode.toUpperCase()];

  return regions ? regions.map(regionCodesAndNames) : [];
}

export function isZipPostalCodeShown(countryCode) {
  switch (countryCode) {
    case 'IE':
      return false;
    default:
      return true;
  }
}

export function availableTimezones() {
  const sortedTimezones = [];

  moment.tz
    .names()
    .map((zone) => ({
      region: zone.split('/')[0],
      city: zone.substring(zone.indexOf('/') + 1).replace(/_/g, ' '),
      zone,
      offset: moment.tz(zone).format('Z')
    }))
    .forEach((zone) => {
      const timezoneRegionIndex = sortedTimezones.findIndex(
        (sZone) => sZone.label === zone.region
      );
      const zoneObj = {
        code: zone.zone,
        name: `${zone.city} (GMT${zone.offset})`
      };

      if (timezoneRegionIndex !== -1) {
        sortedTimezones[timezoneRegionIndex] = {
          ...sortedTimezones[timezoneRegionIndex],
          options: [...sortedTimezones[timezoneRegionIndex].options, zoneObj]
        };
      } else {
        sortedTimezones.push({
          label: zone.region,
          options: [zoneObj]
        });
      }
    });

  return sortedTimezones;
}

export function userIsPhysician(user) {
  return user && ['admin', 'practitioner'].includes(user.role);
}

export function resetPassword(userId) {
  if (window) {
    window.open(`${getApiDomain()}/users/${userId}/set-password`, '_blank');
  }
}

export function isEnglish(locale) {
  return locale === 'en' || locale.split('-')[0] === 'en';
}

export function isJapanese(locale) {
  return locale === 'ja' || locale === 'ja-JP';
}
