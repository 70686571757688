import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { getSupportUrl } from 'services/config.service';
import hands from '../../assets/images/support/img-hands.png';
import './home.scss';

const SupportSidebar = ({ language }) => {
  const { t } = useTranslation('education');
  const supportUrl = getSupportUrl(language);

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div>
          <h2>{t('HAVE_QUESTIONS')}</h2>
          <br />
          <Trans i18nKey="SUPPORT" t={t}>
            Visit our <strong>Customer Support Center</strong>
          </Trans>
          <br />
          <a
            className="cx-link"
            href={supportUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('TAP_CLICK')}.
          </a>
        </div>
        <div className="hands">
          <img alt="" height="365" width="263" src={hands} />
        </div>
      </div>
    </div>
  );
};

SupportSidebar.propTypes = {
  language: PropTypes.string.isRequired
};

export default SupportSidebar;
