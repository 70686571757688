import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import moment from 'services/moment.service';
import styles from './timePicker.module.css';

// accepts and returns time value as an object: { hour: number, minute: number }
export default function TimePicker({ disabled, label, onChange, value = { hour: 9, minute: 0 } }) {

  const [hour, setHour] = useState(value.hour);
  const [ minute, setMinute ] = useState(value.minute);

  useEffect(() => {
    setHour(value.hour);
    setMinute(value.minute);
  }, [value])
 
  function handleBlur(e) {
    const value = e.target.value;
    const [ hour, minute ] = value.split(':');
    setHour(hour);
    setMinute(minute);
    onChange({ hour, minute });
  }
  
  function handleChange(e) {
    const value = e.target.value;
    if(value) {
      const [hour, minute] = value.split(':');
      setHour(hour);
      setMinute(minute);
      onChange({hour, minute});
    }
  }

  return (
    <Form.Group controlId="time-picker">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        className={styles.timePickerInput}
        data-testid="time-picker"
        type="time"
        onChange={handleChange}
        onBlur={handleBlur}
        value={moment()
          .hour(hour)
          .minute(minute)
          .format('HH:mm')}
        readOnly={disabled}
        disabled={disabled}
      />
    </Form.Group>
  );
}

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    hour: PropTypes.number,
    minute: PropTypes.number,
  }),
}