import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhButton from 'components/PhButton/PhButton';

import { Th, Td } from 'components/Tables';

import { isRescue } from 'services/medication.service';
import { withoutAnnotation } from 'services/events.service';

import EventDetails from './EventDetails';
import UsageTimes from './UsageTimes';
import SymptomList from './SymptomList';
import TriggerList from './TriggerList';

import './styles.scss';

const TimelineEvent = ({ event, canModify, editEvent, t }) => (
  <>
    <Th sm={12} md={3} scope="row">
      <EventDetails event={event} t={t} />
    </Th>
    <Td sm={12} md={2}>
      <UsageTimes event={event} t={t} />
    </Td>
    <Td
      sm={12}
      md={3}
      className={classNames({
        'text-center': withoutAnnotation(event)
      })}
    >
      {event.symptoms ? <SymptomList event={event} t={t} /> : null}
      {event.triggers ? <TriggerList event={event} t={t} /> : null}
      {canModify && isRescue(event.medication) && withoutAnnotation(event) && (
        <PhButton
          variant="secondary"
          size="small"
          className="timeline-add-triggers-button"
          onClick={() => editEvent(event)}
        >
          {t('ADD_TRIGGERS')}
        </PhButton>
      )}
    </Td>
    <Td sm={12} md={3} className="timeline-event-edit-container">
      {canModify && (
        <PhButton
          variant="secondary"
          size="small"
          onClick={() => editEvent(event)}
        >
          {t('EDIT')}
        </PhButton>
      )}
    </Td>
  </>
);

TimelineEvent.propTypes = {
  t: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  editEvent: PropTypes.func.isRequired,
  canModify: PropTypes.bool
};

TimelineEvent.defaultProps = {
  canModify: false
};

export default TimelineEvent;
