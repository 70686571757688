import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import moment from './services/moment.service';
import packageJson from '../package.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
      caches: []
    },
    fallbackLng: {
      ca: ['ca-ES'],
      cs: ['cs-CZ'],
      da: ['da-DK'],
      de: ['de-DE'],
      'de-AT': ['de-DE'],
      'de-BE': ['de-DE'],
      'de-CH': ['de-DE'],
      el: ['el-GR'],
      en: ['en-US'],
      'en-CA': ['en-US'],
      'en-IE': ['en-GB'],
      'en-NO': ['en-US'],
      es: ['es-ES'],
      'es-419': ['es-US'],
      'es-XL': ['es-US'],
      fi: ['fi-FI'],
      fr: ['fr-FR'],
      'fr-BE': ['fr-FR'],
      'fr-CA': ['fr-FR'],
      'fr-CH': ['fr-FR'],
      it: ['it-IT'],
      'it-CH': ['it-IT'],
      ja: ['ja-JP'],
      ko: ['ko-KR'],
      nb: ['nb-NO'],
      nl: ['nl-NL'],
      'nl-BE': ['nl-NL'],
      no: ['nb-NO'],
      pt: ['pt-PT'],
      ru: ['ru-RU'],
      sv: ['sv-SE'],
      'sv-FI': ['sv-SE'],
      default: ['en-US']
    },
    ns: [
      'education',
      'translations',
      'timeline',
      'trends',
      'profile',
      'login',
      'notifications',
      'medications',
      'sharing',
      'subscriptions'
    ],
    load: 'currentOnly',
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false // not needed for react!!
    },
    backend: {
      // xhr backend
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { appVersion: packageJson.version }
    },

    // react i18next special options (optional)
    react: {
      wait: true,
      nsMode: 'fallback',
      usePureComponent: false,
      bindI18n: 'languageChanged loaded',
      bindStore: false
    }
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
