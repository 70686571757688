import React from 'react';
import PropTypes from 'prop-types';


const EventAddress = ({ address }) => {
  if (!address || !address.street) {
    return null;
  }

  // logic copy/pasted from angular build
  // todo, reassess logic as valid
  const addressLines = [];

  if (address.street) {
    addressLines.push(address.street);
  }

  if (address.street2) {
    addressLines.push(address.street2);
  }

  if (address.city || address.stateCode || address.stateOrRegion || address.postalCode) {
    const city = address.city ? address.city : '';
    let state = address.stateCode ? address.stateCode : address.stateOrRegion;
    state = state || ''; // set this to empty string if neither state field is valid
    const zip = address.postalCode ? address.postalCode : '';
    const separator1 = (city && (state || zip)) ? ', ' : ' ';
    const separator2 = (state && zip) ? ' ' : '';

    addressLines.push(`${city}${separator1}${state}${separator2}${zip}`);
  }

  return (
    <address className="timeline-event-details">
      {
        addressLines.join(', ')
      }
    </address>
  );
};

EventAddress.propTypes = {
  address: PropTypes.object,
};

EventAddress.defaultProps = {
  address: null,
};

export default EventAddress;
