import { fetchMetrics } from './metrics.controller';
import { pagingTokenFrom } from '../../lib/reduxUtils';

// actions
export const METRICS_REQUEST_INIT = 'METRICS_REQUEST_INIT';
export const METRICS_REQUEST_SUCCESS = 'METRICS_REQUEST_SUCCESS';
export const METRICS_REQUEST_ERROR = 'METRICS_REQUEST_ERROR';

// action creators
const initRequest = (key) => ({
  type: METRICS_REQUEST_INIT,
  key
});

const setEvents = (key, payload, pagingToken) => ({
  type: METRICS_REQUEST_SUCCESS,
  key,
  payload,
  pagingToken
});

const setError = (key, err) => ({
  type: METRICS_REQUEST_ERROR,
  key,
  err
});

// i am a thunk, because I'm an action that returns a function instead of an object
export function getMetrics(key, userId, queryParams = {}) {
  return (dispatch) => {
    dispatch(initRequest(key));
    fetchMetrics(userId, queryParams)
      .then((response) =>
        dispatch(setEvents(key, response, pagingTokenFrom(queryParams))))
      .catch((error) => dispatch(setError(key, error)));
  };
}
