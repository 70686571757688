import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './error-message.scss';
import warningImg from '../../assets/images/warning-fill.svg';

const ErrorMessage = ({
  className = '',
  iconSize = 32,
  message = 'ERROR_MESSAGE'
}) => {
  const { t } = useTranslation(['errors']);
  return (
    <div
      className={`error-message ${className}`.trim()}
      role="alert"
      aria-live="assertive"
    >
      <img src={warningImg} width={iconSize} height={iconSize} alt="" />
      &nbsp;
      {t(message)}
    </div>
  );
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  iconSize: PropTypes.string | PropTypes.number,
  message: PropTypes.string
};

ErrorMessage.defaultProps = {
  iconSize: 32,
  message: 'ERROR_MESSAGE'
};

export default ErrorMessage;
