import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import Notifications from 'react-notification-system-redux';
import { v4 as uuidv4 } from 'uuid';
// Pages
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import AuthenticatedRoute from './routes/Authenticated.route';
import DefaultRoute from './routes/Default.route';
import TimelineContainer from './pages/Timeline/TimelineContainer';
import ControlPatient from 'pages/ControlPatient/ControlPatient';
import HeaderContainer from 'components/Header/HeaderContainer';
import FooterContainer from 'components/Footer/FooterContainer';
import { initSegment } from 'services/analytics.service';
import { getGroupConfig } from 'resources/group/group.actions';
// lazy loading pages not needed immediately
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));
const MedicationsContainer = React.lazy(() =>
  import('./pages/Medications/MedicationsContainer')
);
const NotificationSettingsContainer = React.lazy(() =>
  import('./pages/NotificationSettings/NotificationSettingsContainer')
);
const Physician = React.lazy(() => import('pages/Physician/Physician'));
const SharingContainer = React.lazy(() =>
  import('./pages/Sharing/SharingContainer')
);
const TrendsContainer = React.lazy(() =>
  import('./pages/Trends/TrendsContainer')
);
const UserProfileContainer = React.lazy(() =>
  import('./pages/UserProfile/UserProfileContainer')
);
let allowName = null;


const mapStateToProps = (state) => {
  const { currentUser, me } = state.rUser;
  const { currentGroup } = state.rGroup;

  return {
    currentGroup,
    currentUser,
    fbTracking: currentGroup?.fbTracking,
    me,
    notifications: state.notifications
  };
};

const actionCreators = {
  getGroupConfig
};

function isAllowViewName(patient, group) {
  const trialArms = group?.clinicalTrial?.arms;

  if (trialArms && patient.clinicalTrial) {
    const patientArmId = patient.clinicalTrial.clinicalTrialArmId;
    const armConfig = trialArms.find((arm) => arm.id === patientArmId);

    return armConfig.allowViewName;
  }

  return true;

}

const AuthenticatedApp = ({
  currentGroup,
  currentUser,
  fbTracking,
  getGroupConfig,
  me,
  notifications
}) => {
  useEffect(() => {
    getGroupConfig(currentUser.group.id);

  }, []);

  useEffect(() => {
    if (fbTracking && fbTracking !== 'none') {
      const session_uuid = uuidv4();
      initSegment({ userId: me.id, sessionUuid: session_uuid });
    }

  }, [fbTracking, me]);

  return (
    <>
      <HeaderContainer />
      <main id="app-main-container" className="container-fluid">
        <Switch>
          <AuthenticatedRoute
            exact
            path="/:userId/timeline/:action?"
            component={TimelineContainer}
            groupId={currentUser.group.id}
          />
          <AuthenticatedRoute exact path="/" component={MedicationsContainer} />
          <Suspense fallback={<LoadingSpinner />}>
            <AuthenticatedRoute
              exact
              path="/:userId/profile"
              component={UserProfileContainer}
              groupId={currentUser.group.id}
            />
            <AuthenticatedRoute exact path="/physician" component={Physician} />
            <AuthenticatedRoute
              exact
              path="/:userId/medications"
              component={MedicationsContainer}
              groupId={currentUser.group.id}
            />
            <AuthenticatedRoute
              exact
              path="/:userId/notifications"
              component={NotificationSettingsContainer}
              groupId={currentUser.group.id}
            />
            <AuthenticatedRoute
              exact
              path="/:userId/sharing"
              component={SharingContainer}
              groupId={currentUser.group.id}
            />
            <AuthenticatedRoute
              exact
              path="/:userId/trends"
              component={TrendsContainer}
              groupId={currentUser.group.id}
            />
          </Suspense>
          <DefaultRoute component={NotFound} />
        </Switch>
      </main>
      <FooterContainer />
      <Notifications notifications={notifications} />
    </>
  );
};

AuthenticatedApp.propTypes = {
  notifications: PropTypes.array,
  me: PropTypes.object.isRequired
};

AuthenticatedApp.defaultProps = {
  notifications: []
};

export default compose(
  connect(mapStateToProps, actionCreators),
  withRouter
)(AuthenticatedApp);
