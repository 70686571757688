import React, { Component } from 'react';
import { connect } from "react-redux";

const mapStateToProps = ({ rUser }) => {
  return { session : rUser.me };
};

const container = connect(mapStateToProps);

export default function withSession(WrappedComponent) {
  class withSessionWrapper extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return container(withSessionWrapper);
}
