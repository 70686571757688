import React from 'react';
import PropTypes from 'prop-types';
import MedicationIcon from 'components/MedicationIcon';
import EventAddress from 'components/EventAddress';

import {
  eventType,
  formFactorFor,
  symptomEvent
} from 'services/events.service';


const EventDetails = ({ event, t }) => {
  const type = eventType(event);

  return (
    <div className="display-flex timeline-detail-column">
      <div className="display-inline-block">
        <MedicationIcon type={type} formFactor={formFactorFor(event)} />
      </div>
      <div className="display-inline-block">
        <h4 className="timeline-event-name">
          {symptomEvent(event)
            ? t('NO_MEDICATION_EVENT_LABEL')
            : event.medication.name}
        </h4>
        {event.preemptive ? (
          <div className="timeline-event-details">{t('PREEMPTIVE')}</div>
        ) : (
          <div className="sr-only">{t(`${type.toUpperCase()}_EVENT`)}</div>
        )}
        <EventAddress address={event.address} />
      </div>
    </div>
  );
};

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default EventDetails;
