import React from 'react';
import Col from 'react-bootstrap/Col';
import FooterLink from './FooterLink';
import './footer.scss';

const FooterSection = ({ titleText, section, links = [], t }) => (
  <Col sm={12} md={4}>
    <h4 className="footer-section-title">{t(titleText)}</h4>
    {links.map((link, i) => (
      <FooterLink key={`${section}-link-${i}`} t={t} {...link} />
    ))}
  </Col>
);

export default FooterSection;
