export const API_VERSION = '4.74.0';

// for when we want to switch away from timeline as the default page
export function getDefaultPage() {
  return 'medications';
}

export function getDefaultCaregiverPage() {
  return 'profile';
}

export function getDefaultProviderPage() {
  return 'physician';
}

export function getSupportUrl(userLanguage) {
  const underscored = [
    'en-us',
    'en-au',
    'en-gb',
    'es-mx',
    'es-us',
    'fr-ca',
    'nl-nl',
    'pt-pt'
  ];
  let lang = underscored.includes(userLanguage)
    ? userLanguage.replace('-', '_')
    : userLanguage.split('-')[0];
  if (lang === 'nb') lang = 'no';
  return `https://support.propellerhealth.com/s/?language=${lang}`;
}
