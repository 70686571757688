import React from 'react';
import PropTypes from 'prop-types';
import ItemList from './ItemList';


const SymptomList = ({ event, t }) => (
  <ItemList title="SYMPTOMS" prefix="SYMPTOM" items={event.symptoms} t={t} />
);

SymptomList.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default SymptomList;
