/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import ToastrContainer from 'components/ToastrContainer';

const PageHeader = ({ heading, title, className }) =>
  heading || (
    <h1 className={classNames('page-title', 'page-title--indent', className)}>
      {title}
    </h1>
  );

PageHeader.propTypes = {
  heading: PropTypes.element,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

const PageLayout = (props) => {
  const {
    children,
    layoutClassName,
    hasHeader = true,
    sidebar,
    withToastrContainer,
    heading,
    title
  } = props;

  const colProps = sidebar
    ? {
        sm: 12,
        md: 9,
        lg: 10
      }
    : { sm: 12 };

  return (
    <>
      <Row
        className={classNames(
          'page-content-container',
          'row-max-width',
          layoutClassName
        )}
      >
        {sidebar}
        <Col {...colProps} className="page-content">
          {hasHeader ? <PageHeader heading={heading} title={title} /> : null}
          {children}
        </Col>
      </Row>
      {withToastrContainer && <ToastrContainer />}
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.element,
  hasHeader: PropTypes.bool,
  layoutClassName: PropTypes.string,
  sidebar: PropTypes.element,
  title: PropTypes.string,
  withToastrContainer: PropTypes.bool
};

PageLayout.defaultProps = {
  children: null,
  heading: null,
  hasHeader: true,
  layoutClassName: null,
  sidebar: null,
  withToastrContainer: false,
  title: ''
};

export default PageLayout;
