import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import FlexRow from 'components/FlexRow';
import MedicationIcon from 'components/MedicationIcon';


const AddEventType = ({
  eventTypes,
  onClick,
  type,
  t,
  extreactIcon,
  extreactTitle,
}) => {
  const TYPE = type.toUpperCase();

  return (
    eventTypes.length > 0 && (
      <Col xs={12} md={6}>
        <div className="timeline-event-add-container">
          <div>
            <h4
              id={`${TYPE}_EVENT`}
              className="timeline-event-add-container-title"
            >
              {t(`${TYPE}_EVENT`)}
            </h4>
            <p
              id={`RECORD_${TYPE}_EVENT`}
            >
              {t(`RECORD_${TYPE}_EVENT`)}
            </p>
          </div>
          <div
            role="list"
            aria-labelledby={`${TYPE}_EVENT`}
            aria-describedby={`RECORD_${TYPE}_EVENT`}
          >
            {eventTypes.map((eventType) => (
              <FlexRow
                key={eventType.id || eventType.medication.id}
                className="timeline-event-add-container-row"
                role="listitem"
              >
                <MedicationIcon
                  type={type}
                  formFactor={extreactIcon(eventType)}
                  className="timeline-event-add-icon"
                />
                <Button
                  className="timeline-event-add-button"
                  onClick={() => onClick(eventType)}
                >
                  {extreactTitle(eventType)}
                </Button>
              </FlexRow>
            ))}
          </div>
        </div>
      </Col>
    )
  );
};

AddEventType.propTypes = {
  eventTypes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  extreactIcon: PropTypes.func.isRequired,
  extreactTitle: PropTypes.func.isRequired,
};

export default AddEventType;
