import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FlexRow from 'components/FlexRow';
import Button from 'components/PhButton/PhButton';
import { Link } from 'react-router-dom';
import { login } from 'services/auth.service';
import { isEnglish, isJapanese } from 'services/profile.service';
import './header.scss';

function VisitorHeader() {

  useEffect(() => {
    function OptanonWrapper() { }
    function addScript(src, attr) {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = function () {
        //script has been loaded
      }
      script.src = src;
      for (var i = 0; i < attr.length; i++) {
        script.setAttribute(attr[i].key, attr[i].value);
      }
      head.appendChild(script);
    }
    if (window.location.host.includes('.biz') || window.location.host.includes('localhost:3000')) {
      addScript("https://cdn.cookielaw.org/consent/c850c414-86ff-46cf-9583-3b2bcb39649b/OtAutoBlock.js", []);
      addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", [{ key: 'charset', value: 'UTF-8' }, { key: 'data-domain-script', value: 'c850c414-86ff-46cf-9583-3b2bcb39649b' }]);
    } else {
      addScript("https://cdn.cookielaw.org/consent/eb4ad593-ee82-4795-a05f-d43bd25504f6/OtAutoBlock.js", []);
      addScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", [{ key: 'charset', value: 'UTF-8' }, { key: 'data-domain-script', value: 'eb4ad593-ee82-4795-a05f-d43bd25504f6' }]);
    }
  }, [])

  const { i18n, t } = useTranslation('education');

  function showLearnMore() {
    window.open('https://www.propellerhealth.com/how-it-works/', '_blank');
  }

  return (
    <FlexRow container="header" className="header">
      <FlexRow className="row-max-width">
        <Link
          to={{ pathname: '/' }}
          className="header__logo-container header__logo-link"
        >
          {isJapanese(i18n.language) ? (
            <div
              className="header__logo header__logo-jp"
              role="img"
              aria-label="Propeller logo"
            />
          ) : (
            <div
              className="header__logo"
              role="img"
              aria-label="Propeller logo"
            />
          )}
        </Link>
        <div className="sub-nav">
          {isEnglish(i18n.language) ? (
            <Button variant="secondary" onClick={showLearnMore} size="small">
              {t('LEARN_MORE')}
            </Button>
          ) : null}
          &nbsp;&nbsp;
          <Button onClick={login} size="small">
            {t('LOGIN')}
          </Button>
        </div>
      </FlexRow>
    </FlexRow>
  );
}

export default VisitorHeader;
