import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

import moment from 'services/moment-timezone.service';

import './styles.scss';


function matchOrDefault(text, rx, defaultText) {
  const match = text.match(rx);

  // *1: remove trailing comma
  // *2: for a locale like pt-PT with result of "D [de] MMM [de] ",
  //     remove the trailing escaped sequence (in square brakets)
  return match
    ? match[0]
      .replace(/,\s*$/, '') // *1
      .replace(/\[(?=[a-z]+\]\s*$).+]\s*$/, '') // *2
      .trim()
    : defaultText;
}

// We will want to revisit how we handle order,
// since year is fist for Japanese and Korean
function datePartsForLocale(lng) {
  let fmt = moment.localeData(lng).longDateFormat('ll');
  let date = matchOrDefault(fmt, /(([MD]{1,4})[^Y]*)/i, 'MMM DD');
  let year = matchOrDefault(fmt, /((Y{1,4})[^MD]*)/, 'YYYY');

  return [date, year, fmt];
}

const memoizedDateParts = memoizeOne(datePartsForLocale);

// a prior implementation had nodes to be displayed or hidden based on screen size.
// I simplified it to only return one heading, but we may need to reconsider that
// as we try to handle properly localized dates
const EventDate = ({ date, srOnly }) => {
  const parts = memoizedDateParts(moment.locale());
  const srClass = srOnly ? 'sr-only' : '';
  return (
    <h3 className={`timeline-date-header ${srClass}`}>
      {moment(date).format(parts[0])}{' '}
      <span className="event-date-year subheader">
        {moment(date).format(parts[1])}
      </span>
    </h3>
  );
};

EventDate.propTypes = {
  date: PropTypes.string.isRequired,
  srOnly: PropTypes.bool.isRequired
};

export default EventDate;
