import {
  getApiUrl,
  checkAuthorizedResponse,
  extractJSON,
} from '../../services/request.service';


export function fetchGroup(groupId) {
  return window
    .fetch(`${getApiUrl()}/groups/${groupId}/config`, {
      method: 'GET',
      headers: {
        'x-ph-api-version': '3.97.0',
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
    .then(checkAuthorizedResponse)
    .then(extractJSON);
}

export function unsubscribeFromEmail(userId) {
  return window
    .fetch(`${getApiUrl()}/users/${userId}/unsubscribe`, {
      method: 'PUT',
      headers: {
        'x-ph-api-version': '3.88.0',
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
}
