export const REQUEST_KEYS = {
  daily_events: 'dailyEvents',
  monthly_trends: 'monthlyTrends',
  symptoms: 'symptoms',
  triggers: 'triggers'
};

export const getDailyTrendParams = () => {
  const names = [
    'eventsRescue',
    'eventsRescuePreemptive',
    'eventsRescueNight',
    'adherencePercentActual',
    'unitDosesControllerAdministeredActual',
    'unitDosesControllerPrescribed'
  ];
  return names.join('&name=');
};

export const getMonthlyTrendParams = () => {
  const names = [
    'trendDayOfWeekSunday',
    'trendDayOfWeekMonday',
    'trendDayOfWeekTuesday',
    'trendDayOfWeekWednesday',
    'trendDayOfWeekThursday',
    'trendDayOfWeekFriday',
    'trendDayOfWeekSaturday',
    'trendTimeOfDayMorning',
    'trendTimeOfDayAfternoon',
    'trendTimeOfDayEvening',
    'trendTimeOfDayNight'
  ];
  return names.join('&name=');
};

export const getTriggerParams = () => {
  const names = [
    'trendTriggerAcidReflux',
    'trendTriggerAirPollution',
    'trendTriggerAnimals',
    'trendTriggerAnxietyOrStress',
    'trendTriggerBendingOver',
    'trendTriggerCarryingGroceries',
    'trendTriggerCleaningSupplies',
    'trendTriggerCockroaches',
    'trendTriggerColdAir',
    'trendTriggerCOVID19',
    'trendTriggerDust',
    'trendTriggerExercise',
    'trendTriggerFlu',
    'trendTriggerFoodAllergies',
    'trendTriggerHeatOrHumidity',
    'trendTriggerHousework',
    'trendTriggerIndoorAllergens',
    'trendTriggerIndoorMolds',
    'trendTriggerLaundry',
    'trendTriggerMakingTheBed',
    'trendTriggerMedicines',
    'trendTriggerOther',
    'trendTriggerOutdoorAllergens',
    'trendTriggerOutdoorMolds',
    'trendTriggerPollenGrasses',
    'trendTriggerPollenTrees',
    'trendTriggerPollenWeeds',
    'trendTriggerRain',
    'trendTriggerRespiratoryInfection',
    'trendTriggerShowering',
    'trendTriggerSmellsOrScents',
    'trendTriggerSquatting',
    'trendTriggerStrongEmotion',
    'trendTriggerTobacco',
    'trendTriggerUnknown',
    'trendTriggerVapeOrEcig',
    'trendTriggerWeatherChanges',
    'trendTriggerWildfireSmoke',
    'trendTriggerWind',
    'trendTriggerWoodsmoke',
    'trendTriggerWorkplaceExposure'
  ];
  return names.join('&name=');
};

export const getSymptomParams = () => {
  const names = [
    'trendSymptomWokenAtNight',
    'trendSymptomCough',
    'trendSymptomWheeze',
    'trendSymptomDifficultyBreathing',
    'trendSymptomShortnessOfBreath',
    'trendSymptomChestTightness',
    'trendSymptomActivityLimitations'
  ];
  return names.join('&name=');
};
