// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find-index';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/object/keys';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/includes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import store from './resources/store';
// eslint-disable-next-line no-unused-vars
import './i18n'; // This has to be here so that it gets included in the bundle

/*
import css first, to set up cascade.
If we import components first, then their imported css comes first
*/

import './styles/index.scss';
import { unregister } from './registerServiceWorker';


if (window.PH_ENV && typeof window.PH_ENV === 'object') {
  Object.freeze(window.PH_ENV);
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('app')
);

unregister();
