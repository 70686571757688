import moment from './moment.service';
import { isController } from './medication.service';

// for displaying events in the user experience day and time,
// regardless of where they occurred in the world
const removeDateTimezone = (eventDate) => eventDate.substring(0, 16);

const getEventUsageData = (event) => ({
  id: event.id,
  date: event.date,
  puffs: event.unitDoses
});

// https://stackoverflow.com/questions/10123953/sort-javascript-object-array-by-date
// Turn your strings into dates, and then subtract them
// to get a value that is either negative, positive, or zero.
const sortDates = (a, b) => new Date(b) - new Date(a);

export function getSortedEventsByDate(events) {
  const formattedEvents = [];
  if (events) {
    events
      .sort((a, b) => sortDates(a.date, b.date))
      .forEach((event) => {
        const date = moment(event.date).format('YYYY-MM-DD');
        const dateIndex = formattedEvents.findIndex((evt) => evt.date === date);

        if (dateIndex !== -1) {
          if (isController(event.medication)) {
            // controller events
            const controllerEventIndex = formattedEvents[dateIndex].events.findIndex(
              (evt) => isController(evt.medication)
                && evt.medication.id === event.medication.id
            );

            if (controllerEventIndex !== -1) {
              // controller event already exists for the date
              formattedEvents[dateIndex].events[controllerEventIndex] = {
                ...formattedEvents[dateIndex].events[controllerEventIndex],
                usageList: [
                  ...formattedEvents[dateIndex].events[controllerEventIndex].usageList,
                  getEventUsageData(event)
                ]
              };
            } else {
              // no controller event yet for this date
              formattedEvents[dateIndex].events.push({
                ...event,
                usageList: [getEventUsageData(event)]
              });
            }
          } else {
            // rescue events don't get condensed
            formattedEvents[dateIndex].events.push({
              ...event,
              usageList: [getEventUsageData(event)]
            });
          }
        } else {
          // adding new dates with events
          formattedEvents.push({
            date,
            events: [
              {
                ...event,
                usageList: [getEventUsageData(event)]
              }
            ]
          });
        }
      });
  }

  return formattedEvents;
}
