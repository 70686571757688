import React from 'react';
import PropTypes from 'prop-types';
import './PhButton.scss';

export const Button = ({
  children,
  className = '',
  isDisabled = false,
  onClick,
  size = 'large',
  type = 'button',
  variant = 'primary',
  width
}) => {
  return (
    <button
      className={`ph-button ${variant} ${size} ${className}`.trim()}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      style={{ width }}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.func
  ]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'link']),
  width: PropTypes.string
};

export default Button;
