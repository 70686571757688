import { shape, string, arrayOf, oneOfType, number, oneOf, object } from 'prop-types';


export const medicationType = shape({
  activeIngredients: arrayOf(shape({ category: string, name: string })),
  diseases: arrayOf(string),
  doseRanges: [
    {
      administrationsPerDay: { max: number, min: number },
      age: { high: number, low: number },
      disease: oneOf(['asthma', 'copd']),
      unitDosesPerAdministration: { max: number, min: number }
    }
  ],
  formFactor: string,
  id: string,
  name: string,
  sensors: arrayOf(oneOfType([string, object])),
  shortName: string,
  type: oneOf(['rescue', 'controller'])

});

export const planMedType = shape({
  effectivePeriod: shape({
    end: string | Date,
    start: string | Date
  }),
  medication: medicationType,
  status: oneOf(['active', 'intended', 'stopped']),
  usageList: arrayOf(shape({
    hour: number,
    minute: number,
    unitDoses: number
  })),
});

export const planType = shape({
  medications: arrayOf(planMedType)
});
