import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';


const PageTitle = ({ title, children }) => (
  <Fragment>
    <Helmet>
      <title>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {title} - Propeller
      </title>
    </Helmet>
    {children}
  </Fragment>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageTitle;
