import { getToken, isUserUnauthorized, logout } from './auth.service';
import { API_VERSION } from './config.service';

export function getApiHeaders() {
  return {
    'x-ph-api-version': API_VERSION,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  };
}

export function getApiDomain(domain=null) {
  // no default here to ensure error if config not set
  const { API_DOMAIN = '_API_URL_' } = window.PH_ENV || {};
  if (API_DOMAIN && API_DOMAIN !== '_API_URL_') {
    return API_DOMAIN;
  }
  //to test the functionality on multiple domain I have added a domain param, because in local only biz works
  if(domain){
    return domain;
  }
  return process.env.REACT_APP_API_URL;
}

export function getApiUrl() {
  return `${getApiDomain()}/api`;
}

export function extractJSON(response) {
  return response ? response.json() : response;
}

export function checkAuthorizedResponse(response) {
  if (response.ok) {
    return response;
  }

  if (isUserUnauthorized(response)) {
    //logout();
  }

  throw response;
}

export function checkUnauthorizedResponse(response) {
  if (response.ok) return response;

  if (response) {
    return response.json().then((data) => {
      throw data;
    });
  }

  throw response;
}
