import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import momentTimezone from 'services/moment-timezone.service';
import packageJson from '../../../package.json';
import FooterLink from './FooterLink';
import FooterSection from './FooterSection';

const currentYear = momentTimezone().format('YYYY');
const FOOTER_SECTIONS = [
  {
    titleText: 'OUR_COMPANY',
    section: 'company',
    links: [
      { text: 'ABOUT_US', url: 'https://www.propellerhealth.com/about-us/' },
      {
        text: 'HOW_IT_WORKS',
        url: 'https://www.propellerhealth.com/how-it-works/'
      },
      { text: 'PRESS', url: 'https://propellerhealth.com/press/' },
      { text: 'JOBS', url: 'https://propellerhealth.com/careers/' }
    ]
  },
  {
    titleText: 'MORE_INFO',
    section: 'info',
    links: [
      { text: 'HELP_RESOURCES', url: 'https://support.propellerhealth.com/' },
      {
        text: 'IPHONE_APP',
        url: 'https://itunes.apple.com/us/app/asthmapolis/id520741533?mt=8'
      },
      {
        text: 'ANDROID_APP',
        url: 'https://play.google.com/store/apps/details?id=com.asthmapolis.mobile'
      },
      {
        text: 'USER_AGREEMENT',
        url: 'https://support.propellerhealth.com/s/article/Privacy-Policy-Terms-and-Conditions?language=en_US'
      },
      {
        text: 'PRIVACY_POLICY',
        url: 'https://support.propellerhealth.com/s/article/Privacy-Policy-Terms-and-Conditions?language=en_US'
      },
      { text: 'CONTACT_US', url: 'https://propellerhealth.com/contact/' }
    ]
  },
  {
    titleText: 'SOCIAL_MEDIA',
    section: 'social',
    links: [
      {
        text: 'PROPELLER_BLOG',
        url: 'https://www.propellerhealth.com/press/'
      },
      {
        text: 'TWITTER',
        url: 'https://twitter.com/propellerhealth'
      },
      {
        text: 'FACEBOOK',
        url: 'https://facebook.com/propellerhealth'
      }
    ]
  }
];

const Footer = ({ isUserLoggedIn, t }) => (
  <Container className="footer-container" as="footer">
    {!isUserLoggedIn ? (
      <Row className="row-max-width">
        <Col sm={12} md={10} lg={8} className="offset-md-1 offset-lg-2">
          <Container className="justify-content-sm-center">
            <Row>
              {FOOTER_SECTIONS.map((section) => (
                <FooterSection key={section.section} t={t} {...section} />
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
    ) : null}
    <p id="footer-copyright">
      {t('FOOTER_COPYRIGHT', { currentYear })} CE {t('ALL_RIGHTS_RESERVED')}. v
      {packageJson.version}
    </p>
    {isUserLoggedIn ? (
      <div id='footer-privacy-policy'>
        <FooterLink t={t} url={'https://support.propellerhealth.com/s/article/Privacy-Policy-Terms-and-Conditions?language=en_US'} text='PRIVACY_POLICY' />
        <FooterLink id='user-agreement' t={t} url={'https://support.propellerhealth.com/s/article/Privacy-Policy-Terms-and-Conditions?language=en_US'} text='USER_AGREEMENT' />
      </div>
    ) : null}
  </Container>
);

Footer.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default Footer;
