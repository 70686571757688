import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initKeycloak } from 'services/auth.service';
import { getUserProfile } from 'resources/user/user.actions';
import AuthenticatedApp from 'AuthenticatedApp';
import UnauthenticatedApp from 'UnauthenticatedApp';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';


const mapStateToProps = (state) => {
  const { me } = state.rUser;
  return {
    me,
  };
};



const dispatchProps = {
  getUserProfile,
};

const App = ({ getUserProfile, me }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    initKeycloak(getUserProfile).then(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (me) {
    return <AuthenticatedApp />;
  }

  return <UnauthenticatedApp />;
};

export default connect(mapStateToProps, dispatchProps)(App);
