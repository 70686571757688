export function getTLD(location) {
  return location.hostname.split('.').pop();
}

export function getSubdomain(location) {
  const subdomain = location.hostname.split('.').shift();
  return subdomain === 'localhost' ? 'try' : subdomain;
}

export function parseQueryString(queryString) {
  return queryString
    .substring(1)
    .split('&')
    .reduce((obj, query) => {
      if (query) {
        let parts = query.split('=');
        // eslint-disable-next-line no-param-reassign
        obj[parts[0]] = !obj[parts[0]]
          ? decodeURIComponent(parts[1])
          : [].concat(obj[parts[0]], decodeURIComponent(parts[1]));
      }
      return obj;
    }, {});
}

export function curry(f) {
  return (a) => (b) => f(a, b);
}

export function isDevEnvironment() {
  return process.env.NODE_ENV === 'development';
}

export function getObjectLength(obj) {
  return Object.keys(obj).length;
}

export function isObjectEmpty(obj) {
  return getObjectLength(obj) === 0 && obj.constructor === Object;
}

export const SYMPTOMS = [
  'activityLimitations',
  'chestTightness',
  'cough',
  'difficultyBreathing',
  'shortnessOfBreath',
  'wheeze',
  'wokenAtNight'
];

// for displaying triggers in categories
export const TRIGGERS = [
  {
    label: 'indoorAllergens',
    triggers: [
      'animals',
      'cockroaches',
      'dust',
      'indoorAllergens',
      'indoorMolds'
    ]
  },
  {
    label: 'outdoorAllergens',
    triggers: [
      'outdoorAllergens',
      'outdoorMolds',
      'pollenGrasses',
      'pollenTrees',
      'pollenWeeds'
    ]
  },
  {
    label: 'airPollutants',
    triggers: [
      'airPollution',
      'cleaningSupplies',
      'wildfireSmoke',
      'smellsOrScents',
      'tobacco',
      'vapeOrEcig',
      'woodsmoke',
      'workplaceExposure'
    ]
  },
  {
    label: 'weather',
    triggers: ['coldAir', 'heatOrHumidity', 'rain', 'weatherChanges', 'wind']
  },
  {
    label: 'illness',
    triggers: ['covid19', 'flu', 'respiratoryInfection']
  },
  {
    label: 'lifestyle',
    triggers: ['anxietyOrStress', 'exercise', 'strongEmotion']
  },
  {
    label: 'dailyLiving',
    triggers: [
      'bendingOver',
      'carryingGroceries',
      'housework',
      'laundry',
      'makingTheBed',
      'showering',
      'squatting'
    ]
  },
  {
    label: 'health',
    triggers: ['acidReflux', 'foodAllergies', 'medicines']
  },
  {
    label: 'otherUnknown',
    triggers: ['other', 'unknown']
  }
];

export function padLeft(val, length = 2, char = '0') {
  let paddedValue = val.toString();
  while (paddedValue.length < length) {
    paddedValue = char + paddedValue;
  }

  return paddedValue;
}

export function formatUsageTime(time) {
  return padLeft(time);
}

export function formatMacAddressForDisplay(mac) {
  return mac ? mac.slice(-8).toUpperCase() : mac;
}

export function formatMacAddressForSave(mac) {
  return mac ? `F8:FE:5C:${mac}` : mac;
}

// https://ourcodeworld.com/articles/read/278/how-to-split-an-array-into-chunks-of-the-same-size-easily-in-javascript
export function chunkArray(myArray, chunkSize) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
}

// https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
export function uniq(myArr, prop) {
  return myArr.filter(
    (obj, pos, arr) =>
      arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
  );
}
export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm';

export function isSensorEvent(anEvent) {
  return anEvent && anEvent.source === 'sensor';
}

export function isEnterKeyWasPressed(event) {
  return event && event.keyCode === 13;
}
