import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PhCheckbox from 'components/PhCheckbox';
import { TRIGGERS } from 'services/util.service';
import './trigger-symptom-list.scss';

class TriggerList extends Component {
  constructor(props) {
    super(props);

    const { items } = this.props;

    this.state = {
      items
    };

    this.addRemoveTrigger = this.addRemoveTrigger.bind(this);
  }

  addRemoveTrigger(trigger) {
    const { update } = this.props;
    const { items } = this.state;
    const i = items.indexOf(trigger);

    if (i !== -1) {
      items.splice(i, 1);
    } else {
      items.push(trigger);
    }

    update(items);
    this.setState({ items });
  }

  render() {
    const { t } = this.props;
    const { items } = this.state;

    return TRIGGERS.map((category) => (
      <div
        className="trigger-category-container"
        key={`trigger-category-${category.label}`}
        role="group"
        aria-labelledby={`category-group-label-${category.label}`}
      >
        <div className={`trigger-category-icon ${category.label}`} />
        <h4
          className="trigger-category-title"
          id={`category-group-label-${category.label}`}
        >
          {t(`TRIGGER_CATEGORY_${category.label.toUpperCase()}`)}
        </h4>

        {category.triggers.map((trigger) => {
          const id = `trigger-${category.label}-${trigger}`;

          return (
            <PhCheckbox
              key={id}
              id={id}
              labelText={t(`TRIGGER_${trigger.toUpperCase()}`)}
              checked={items.indexOf(trigger) !== -1}
              onChange={() => this.addRemoveTrigger(trigger)}
            />
          );
        })}
      </div>
    ));
  }
}

TriggerList.propTypes = {
  t: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  items: PropTypes.array
};

TriggerList.defaultProps = {
  items: []
};

export default withTranslation(['translations'])(TriggerList);
