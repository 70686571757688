import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import omit from 'lodash/omit';

import moment, {
  formatDate,
  getMomentDate,
  COMMON_DATE_FORMAT,
} from 'services/moment.service';

import './index.scss';


class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    const { value, isOpen } = this.props;
    const momentValue = getMomentDate(value);

    this.state = {
      isOpen,
      value: momentValue,
    };

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // This makes the PatientProfileForm testable
  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;

    if (prevValue === value) {
      return;
    }

    const momentValue = getMomentDate(value);

    this.setValue(momentValue);
  }

  // This makes the PatientProfileForm testable
  componentDidCatch() {
    console.log('react-date error');
  }

  setValue(value, callback) {
    this.setState({ value }, callback);
  }

  setOpenState(isOpen, callback) {
    this.setState({ isOpen }, callback);
  }

  handleValueChange(value) {
    this.setValue(value, () => this.onDateChange(value));
  }

  onDateChange(momentDate) {
    const { onDateChange } = this.props;
    const date = formatDate(momentDate);

    onDateChange(date, momentDate);
  }

  handleFocusChange({ focused }) {
    const { onFocusChange } = this.props;

    this.setOpenState(focused, () => onFocusChange(focused));
  }

  getOtherProps() {
    return omit(
      this.props,
      [
        'id',
        'value',
        'isOpen',
        'onDateChange',
        'onFocusChange',
        'format',
      ],
    );
  }

  render() {
    const { id } = this.props;
    const { hasError, value, isOpen } = this.state;

    return (
      <>
        { hasError ? <input id={id} type="date" value={value} onChange={this.handleValueChange} />
      : (
        <SingleDatePicker
          id={id}
          date={value}
          focused={isOpen}
          onDateChange={this.handleValueChange}
          onFocusChange={this.handleFocusChange}
          numberOfMonths={1}
          isOutsideRange={() => false}
          enableOutsideDays
          noBorder
          block
          readOnly
          hideKeyboardShortcutsPanel
          {...this.getOtherProps()}
        />
)}
      </>
    );
  }
}

DatePickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
  ]),
  isOpen: PropTypes.bool,
  onDateChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  format: PropTypes.string,
};

DatePickerInput.defaultProps = {
  value: null,
  isOpen: false,
  onDateChange: () => {},
  onFocusChange: () => {},
  format: COMMON_DATE_FORMAT,
};

export default DatePickerInput;
