import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { isRescue, isController } from 'services/medication.service';

import AddEventType from './AddEventType';


const AddEventModal = ({
  plan,
  show,
  onHide,
  selectMedication,
  allowEditSymptomEvents,
  t
}) => {
  const rescueMeds = plan ? plan.medications.filter((m) => isRescue(m.medication)) : [];
  const controllerMeds = plan ? plan.medications.filter((m) => isController(m.medication)) : [];

  return (
    <Modal
      dialogClassName="timeline-modal"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <h2 className="modal-title">{t('ADD_MEDICATION_USE')}</h2>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <AddEventType
              type="rescue"
              eventTypes={rescueMeds}
              onClick={selectMedication}
              extreactIcon={({ medication }) => medication.formFactor}
              extreactTitle={({ medication }) => medication.name}
              t={t}
            />
            <AddEventType
              type="controller"
              eventTypes={controllerMeds}
              onClick={selectMedication}
              extreactIcon={({ medication }) => medication.formFactor}
              extreactTitle={({ medication }) => medication.name}
              t={t}
            />
          </Row>
          <Row>
            {allowEditSymptomEvents && (
              <AddEventType
                type="symptom"
                eventTypes={[{
                  id: 'symptom',
                  formFactor: 'symptom',
                  title: t('TRACK_SYMPTOMS'),
                }]}
                onClick={selectMedication}
                extreactIcon={({ formFactor }) => formFactor}
                extreactTitle={({ title }) => title}
                t={t}
              />
            )}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('CLOSE')}</Button>
      </Modal.Footer>
    </Modal>
  );
};


AddEventModal.propTypes = {
  plan: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectMedication: PropTypes.func.isRequired,
  allowEditSymptomEvents: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default AddEventModal;
