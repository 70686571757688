import React from 'react';
import PropTypes from 'prop-types';

import './itemList.scss';


const ItemList = ({ title, prefix, items, t }) =>
  items.length > 0 && (
    <div className="item-list">
      <h5>{t(title)}:</h5>
      <ul>
        {items.map((item) => (
          <li key={item}>{t(`${prefix}_${item.toUpperCase()}`)}</li>
        ))}
      </ul>
    </div>
  );

ItemList.propTypes = {
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default ItemList;
