import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from './Header';
import { getUserById, getUserProfile } from '../../resources/user/user.actions';
import { userIsPhysician } from '../../services/profile.service';
import equal from 'fast-deep-equal';


const mapStateToProps = ({ rUser }) => {
  const { me, currentUser } = rUser;

  return {
    isUserLoggedIn: !!me && !!currentUser,
    me,
    currentUser,
    userIsPhysician: userIsPhysician(me)
  };
};


const dispatchProps = {
  getUserById,
  getUserProfile
};

export default compose(
  withTranslation(['translations', 'medications', 'education']),
  connect(
    mapStateToProps,
    dispatchProps
  )
)(Header);
